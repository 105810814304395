import { Button, IconButton } from "@mui/material";
import React, { ReactNode } from "react";

type CustomButtonProps = {
  type: "outlined" | "contained" | "text";
  color: "inherit" | "primary" | "secondary" | "error" | "success";
  size: "small" | "medium" | "large";
  label?: string;
  component?: "label" | "button";
  width?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const CustomButton = ({
  label,
  type,
  color,
  size,
  component = "button",
  width,
  startIcon,
  endIcon,
  disabled = false,
  onClick,
}: CustomButtonProps) => {
  return label ? (
    // 일반 버튼
    <Button
      component={component}
      variant={type}
      color={color}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      style={width ? { width } : undefined}
      sx={{
        textTransform: "none",
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    // 아이콘 버튼
    <Button
      component={component}
      variant={type}
      color={color}
      disabled={disabled}
      size={size}
      style={width ? { width } : undefined}
      onClick={onClick}
      sx={{
        minWidth: "auto", // 텍스트 버튼 기본 최소 너비 제거
        padding: 1, // 패딩 조정
      }}
    >
      {startIcon}
    </Button>
  );
};

export default CustomButton;

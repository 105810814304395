import {
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import React, { ReactNode, useState } from "react";
import MoreMenu from "../../moreMenu/MoreMenu";
import { ButtonType } from "../../../types/common/commonTypes";

type CustomListItemProps = {
  primary: string | ReactNode;
  icon?: ReactNode;
  isLastItem?: boolean;
  secondary?: string | ReactNode;
  buttonDisabled?: boolean;
  observer?: (instance: HTMLDivElement) => void;
  btnList?: ButtonType[];
  customButton?: ReactNode;
  onClick?: () => void;
};

const CustomListItem = ({
  primary,
  icon,
  isLastItem = false,
  secondary,
  btnList,
  buttonDisabled,
  customButton,
  observer,
  onClick,
}: CustomListItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (buttonDisabled) return;

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItemButton ref={observer} onClick={onClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} secondary={secondary} />
        {btnList && (
          <IconButton
            edge="end"
            aria-label="more"
            onClick={handleClick}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={open ? { backgroundColor: "#d9dde1" } : undefined}
            color={buttonDisabled ? "default" : "primary"}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        {customButton && (
          <IconButton edge="end" aria-label="checkbox">
            {customButton}
          </IconButton>
        )}
      </ListItemButton>

      {btnList && (
        <MoreMenu
          id="basic-menu"
          direction="left"
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          buttonList={btnList}
        />
      )}

      {!isLastItem && <Divider variant="fullWidth" component="li" />}
    </>
  );
};

export default CustomListItem;

import React from "react";
import { StyledSecondary } from "./StyledSecondary";
import {
  FolderItemType,
  ProjectItemType,
} from "../../../../types/common/projectTypes";
import dayjs from "dayjs";
import { isProjectItem } from "../../../../types/typeguard/typeguard";

type ListSecondaryType = {
  item: ProjectItemType | FolderItemType;
};

const ListSecondary = ({ item }: ListSecondaryType) => {
  return (
    <StyledSecondary>
      <span className="description">
        {isProjectItem(item)
          ? item.projectDescription
          : `${item.projectCount}개의 프로젝트`}
      </span>
      <span className="created-date">
        {isProjectItem(item) &&
          (item.createdDate
            ? dayjs(item.createdDate).format("YYYY-MM-DD")
            : "-")}
      </span>
    </StyledSecondary>
  );
};

export default ListSecondary;

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { StyledDetailPopup } from "./StyledDetailPopup";
import CustomTable from "../../table/CustomTable";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import CustomButton from "../../input/button/CustomButton";

import { DeviceType, MarkerDataType } from "../../../types/common/commonTypes";
import {
  deleteMark,
  getMarksDetail,
  getProjectDetail,
} from "../../../api/projectApi";
import { CustomSelect } from "../../input/select/CustomSelect";
import dayjs from "dayjs";
import {
  floatsAreEqual,
  formatElapsedTime,
  getDetectionRangeLabel,
  getDeviceTypeLabel,
} from "../../../utils/commonUtils";
import { CustomTextField } from "../../input/textField/CustomTextField";
import useDialogStore from "../../../stores/useDialogStore";
import useAlertStore from "../../../stores/useAlertStore";
import { useLocation } from "react-router-dom";
import useProjectStore from "../../../stores/useProjectStore";
import { FORMULA_KEYS, MEASURE_REFERENCE } from "../../../const/measureConst";
import { convertFormulas, interpolate } from "../../../utils/ConversionFormula";

type DetailPopupProps = {
  selectedCoordinate: number;
  currentMarker: MarkerDataType | null;
  markList: MarkerDataType[];
  deviceType: DeviceType;
  onSave: (markerData: MarkerDataType) => void;
  onCancel: () => void;
  setMarkList: (markerList: MarkerDataType[]) => void;
  setDeviceType: (deviceType: DeviceType) => void;
};

const DEVICE_OPTION_LIST = [
  { label: "하이브리드", value: "hybrid" },
  { label: "스트렝스", value: "strength" },
  { label: "세티메타", value: "setimeta" },
];

/**
 * @description HD 측정 후 입력받은 정보를 토대로 계산된 정보를 보여주는 팝업. currentMarker가 있으면 현재 새로운 측정 정보를 추가하는 중이고, 그게 아니라면 조회로 기존 측정 이력을 보고 있는 것
 */
const DetailPopup = forwardRef(
  (
    {
      selectedCoordinate,
      currentMarker,
      markList,
      deviceType,
      setDeviceType,
      onSave,
      onCancel,
      setMarkList,
    }: DetailPopupProps,
    ref
  ) => {
    const pathname = useLocation().pathname;
    const { openDialog, closeDialog } = useDialogStore();
    const { projectDetail, setProjectDetail } = useProjectStore();
    const { activeAlert } = useAlertStore();
    const contentRef = useRef<HTMLDivElement>(null);
    // const [deviceType, setDeviceType] = useState<DeviceType>("hybrid");
    // const [markList, setMarkList] = useState<any>([]);
    const [checkingTime, setCheckingTime] = useState({
      fiveMPaTime: "",
      initialSettingTime: "",
      finalSettingTime: "",
    });
    const [concreteTemperature, setConcreteTemperature] = useState(0);

    useImperativeHandle(
      ref,
      () => ({
        checkingTime,
      }),
      [checkingTime]
    );

    const fetchProjectDetail = async () => {
      if (!projectDetail?.projectId) throw Error();
      const result = await getProjectDetail(projectDetail.projectId);

      try {
        if (result.isSuccess && result.resultList) {
          setProjectDetail({ ...projectDetail, ...result.resultList });
          const isDeleted = !result.resultList.coordinateList?.some(
            (coordinate: any) => {
              return coordinate.coordinateId === selectedCoordinate;
            }
          );

          if (isDeleted) {
            onCancel();
          }
        } else {
          throw Error();
        }
      } catch (e) {
        activeAlert({
          type: "error",
          message: "상세 정보를 불러오지 못 했습니다. 다시 시도해 주세요.",
          timeout: 3000,
        });
      }
    };

    const fetchCoordinateDetail = async (deviceType?: DeviceType) => {
      const result = await getMarksDetail(
        selectedCoordinate,
        deviceType ?? "hybrid"
      );

      if (result.isSuccess) {
        setMarkList(result.resultList);
      }
    };

    const handleDeviceType = (value: DeviceType) => {
      setDeviceType(value);
      fetchCoordinateDetail(value);
    };

    const onDeleteClick = async (markingId: string) => {
      openDialog({
        title: "이력 삭제",
        content: "이력을 삭제하시겠습니까?",
        size: "small",
        state: "info",
        button: {
          label: "삭제",
          onClick: () => removeMark(markingId),
        },
        secondButton: {
          label: "취소",
          onClick: () => closeDialog(),
        },
      });
    };

    const removeMark = async (markingId: string) => {
      const result = await deleteMark(markingId);
      if (result.isSuccess) {
        await fetchCoordinateDetail(deviceType);
        await fetchProjectDetail();
        activeAlert({
          type: "success",
          message: "이력이 삭제되었습니다.",
          timeout: 3000,
        });
      }
      closeDialog();
    };

    const checkRichTime = () => {
      const timeResult = {
        fiveMPaTime: "",
        initialSettingTime: "",
        finalSettingTime: "",
      };

      const detectionTypes: {
        strength: MarkerDataType[];
        setimeta: MarkerDataType[];
      } = {
        strength: [],
        setimeta: [],
      };

      if (deviceType === "hybrid") {
        markList.forEach((mark: MarkerDataType) => {
          if (mark.detectionRange === "strength") {
            detectionTypes.strength.push(mark);
          } else if (mark.detectionRange === "setting") {
            detectionTypes.setimeta.push(mark);
          } else if (mark.detectionRange.includes("hy")) {
            const [
              hybrid15setting,
              hybrid20setting,
              hybrid15strength,
              hybrid20strength,
            ] = FORMULA_KEYS.map((key: any) =>
              convertFormulas(mark.concreteHd, key)
            );

            const [_, thickness, detectionRange] =
              mark.detectionRange.split("-");

            if (detectionRange === "se") {
              detectionTypes.setimeta.push(mark);
              if (thickness === "15") {
                detectionTypes.strength.push({
                  ...mark,
                  convertedMpa: hybrid15strength,
                });
              } else if (thickness === "20") {
                detectionTypes.strength.push({
                  ...mark,
                  convertedMpa: hybrid20strength,
                });
              }
            } else if (detectionRange === "st") {
              detectionTypes.strength.push({
                ...mark,
                convertedMpa: hybrid15strength,
              });

              if (thickness === "15") {
                detectionTypes.setimeta.push({
                  ...mark,
                  convertedMpa: hybrid15setting,
                });
              } else if (thickness === "20") {
                detectionTypes.setimeta.push({
                  ...mark,
                  convertedMpa: hybrid20setting,
                });
              }
            }
          }
        });
      } else {
        detectionTypes[deviceType].push(...markList);
      }

      // 5MPa 도달 시간 찾기
      if (detectionTypes.strength.length > 0) {
        const sortedData = detectionTypes.strength.sort(
          (a, b) =>
            new Date(a.nowTime).getTime() - new Date(b.nowTime).getTime()
        );

        const targetMarkIndex = sortedData.findIndex(
          (mark: MarkerDataType) => mark.convertedMpa >= 5
        );

        if (targetMarkIndex !== -1) {
          const previousMark = sortedData[targetMarkIndex - 1];
          const currentMark = sortedData[targetMarkIndex];

          const interpolatedTime = interpolate(
            previousMark ? previousMark.convertedMpa : 0,
            currentMark.convertedMpa,
            previousMark
              ? new Date(previousMark.nowTime).getTime() -
                  new Date(previousMark.pouringTime).getTime()
              : 0,
            new Date(currentMark.nowTime).getTime() -
              new Date(currentMark.pouringTime).getTime(),
            5
          );

          timeResult.fiveMPaTime = formatElapsedTime(interpolatedTime);
        }
      }

      if (detectionTypes.setimeta.length > 0) {
        const sortedData = detectionTypes.setimeta.sort(
          (a, b) =>
            new Date(a.nowTime).getTime() - new Date(b.nowTime).getTime()
        );

        // 초결 시간 찾기
        const initialMarkIndex = sortedData.findIndex(
          (mark: MarkerDataType) =>
            mark.convertedMpa > MEASURE_REFERENCE.initialSettingTime
        );

        if (initialMarkIndex !== -1) {
          const previousMark = sortedData[initialMarkIndex - 1];
          const currentMark = sortedData[initialMarkIndex];

          const interpolatedTime = interpolate(
            previousMark ? previousMark.convertedMpa : 0,
            currentMark.convertedMpa,
            previousMark
              ? new Date(previousMark.nowTime).getTime() -
                  new Date(previousMark.pouringTime).getTime()
              : 0,
            new Date(currentMark.nowTime).getTime() -
              new Date(currentMark.pouringTime).getTime(),
            3.5
          );

          timeResult.initialSettingTime = formatElapsedTime(interpolatedTime);
        }

        // 종결시간 찾기
        const finalMarkIndex = sortedData.findIndex(
          (mark: MarkerDataType) =>
            mark.convertedMpa > MEASURE_REFERENCE.finalSetting
        );

        if (finalMarkIndex !== -1) {
          const previousMark = sortedData[finalMarkIndex - 1];
          const currentMark = sortedData[finalMarkIndex];

          const interpolatedTime = interpolate(
            previousMark ? previousMark.convertedMpa : 0,
            currentMark.convertedMpa,
            previousMark
              ? new Date(previousMark.nowTime).getTime() -
                  new Date(previousMark.pouringTime).getTime()
              : 0,
            new Date(currentMark.nowTime).getTime() -
              new Date(currentMark.pouringTime).getTime(),
            28
          );

          timeResult.finalSettingTime = formatElapsedTime(interpolatedTime);
        }
      }

      // 소요 시간 관련 정보 저장
      setCheckingTime(timeResult);
    };

    const rows = useCallback(
      (marker: MarkerDataType, isCurrent?: boolean) => {
        if (!marker) return [];
        return [
          {
            name: "현재 시간",
            key: "currentTime",
            width: 100,
            rowRender: (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {dayjs(marker.nowTime).format("YYYY-MM-DD HH:mm")}
              </Typography>
            ),
          },
          {
            name: "타설 시간",
            key: "pouringTime",
            rowRender: (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {dayjs(marker.pouringTime).format("YYYY-MM-DD HH:mm")}
              </Typography>
            ),
          },
          {
            name: "콘크리트 온도",
            key: "concreteTemperature",
            rowRender: isCurrent ? (
              <CustomTextField
                id="concreteTemperature"
                type="number"
                value={concreteTemperature}
                handleChange={(value) =>
                  setConcreteTemperature(parseFloat(value))
                }
              />
            ) : (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {marker.concreteTemperature}
              </Typography>
            ),
          },
          {
            name: "기기 유형",
            key: "deviceType",
            rowRender: (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {getDeviceTypeLabel(marker.deviceType)}
              </Typography>
            ),
          },
          {
            name: "측정 범위",
            key: "detectionRange",
            rowRender: (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {getDetectionRangeLabel(marker.detectionRange)}
              </Typography>
            ),
          },
          {
            name: "HD",
            key: "HD",
            rowRender: (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {marker.concreteHd}
              </Typography>
            ),
          },
          {
            name: "MPa",
            key: "MPa",
            rowRender: (
              <Typography
                variant="body1"
                sx={{ height: "40px", lineHeight: "45px" }}
              >
                {marker.convertedMpa}
              </Typography>
            ),
          },
        ];
      },
      [concreteTemperature]
    );

    const richTimeRows = useMemo(() => {
      let rowsList = [
        {
          name: "초결 시간",
          key: "firstTime",
          width: 100,
          rowRender: (
            <Typography
              variant="body1"
              sx={{ height: "40px", lineHeight: "45px" }}
            >
              {checkingTime.initialSettingTime
                ? checkingTime.initialSettingTime + "시간"
                : "-"}
            </Typography>
          ),
        },
        {
          name: "종결 시간",
          key: "lastTime",
          width: 100,
          rowRender: (
            <Typography
              variant="body1"
              sx={{ height: "40px", lineHeight: "45px" }}
            >
              {checkingTime.finalSettingTime
                ? checkingTime.finalSettingTime + "시간"
                : "-"}
            </Typography>
          ),
        },
        {
          name: "5MPa 도달 시간",
          key: "fiveMPaTime",
          width: 100,
          rowRender: (
            <Typography
              variant="body1"
              sx={{ height: "40px", lineHeight: "45px" }}
            >
              {checkingTime.fiveMPaTime
                ? checkingTime.fiveMPaTime + "시간"
                : "-"}
            </Typography>
          ),
        },
      ];
      switch (deviceType) {
        case "strength":
          return [{ ...rowsList[2] }];
        case "setimeta":
          return [{ ...rowsList[0] }, { ...rowsList[1] }];
        default:
          return rowsList;
      }
    }, [checkingTime, deviceType]);

    useEffect(() => {
      if (contentRef?.current && currentMarker) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }

      checkRichTime();
    }, [markList]);
    useEffect(() => {
      if (selectedCoordinate) fetchCoordinateDetail(currentMarker?.deviceType);
    }, []);

    return (
      <>
        <DialogContent
          ref={contentRef}
          dividers
          sx={{ backgroundColor: "#f0f0f0" }}
        >
          <StyledDetailPopup>
            <div className="detail-title-area">
              <Typography variant="h6">상세 정보</Typography>
              {!currentMarker && (
                <div className="type-wrap">
                  <CustomSelect
                    id="tool-type"
                    width="130px"
                    label="기기 유형"
                    value={deviceType}
                    handleChange={(value) =>
                      handleDeviceType(
                        value as "hybrid" | "strength" | "setimeta"
                      )
                    }
                    optionList={DEVICE_OPTION_LIST}
                  />
                </div>
              )}
            </div>
            <div className="table-list-wrap">
              {markList.map((mark: any, index: number) => (
                <div className="table-wrap" key={mark.markingId}>
                  <div className="table-header">
                    <Typography variant="subtitle1">
                      {index + 1}번째 측정
                    </Typography>
                    {!currentMarker && pathname.includes("edit") && (
                      <CustomButton
                        label="이력 삭제"
                        type="contained"
                        color="primary"
                        size="small"
                        onClick={() => onDeleteClick(mark.markingId)}
                      />
                    )}
                  </div>

                  <CustomTable key={index} rows={rows(mark)} />
                </div>
              ))}
              {currentMarker && (
                <CustomTable rows={rows(currentMarker, true)} />
              )}

              {!currentMarker && markList.length > 0 && (
                <>
                  <Typography variant="subtitle1">통계</Typography>
                  <CustomTable rows={richTimeRows} />
                </>
              )}

              {markList.length === 0 && !currentMarker && (
                <div className="empty-list-notice">
                  해당하는 기기 유형의 측정 이력이 없습니다.
                  <br />
                  기기 유형 필터를 변경해 주세요.
                </div>
              )}
            </div>
          </StyledDetailPopup>
        </DialogContent>
        <DialogActions>
          <CustomButton
            label="닫기"
            type="outlined"
            color="primary"
            size="large"
            onClick={onCancel}
          />
          {currentMarker && (
            <CustomButton
              label="저장"
              width="100px"
              type="contained"
              color="primary"
              size="large"
              onClick={() => {
                if (currentMarker)
                  onSave({ ...currentMarker, concreteTemperature });
              }}
            />
          )}
        </DialogActions>
      </>
    );
  }
);

export default DetailPopup;

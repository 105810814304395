import { DeviceType } from "../types/common/commonTypes";
import { CreateMarkerForm } from "../types/common/projectTypes";
import { checkErrorType } from "../utils/apiUtils";
import axiosInstance from "./axiosInstance";

/**
 * @description 프로젝트 리스트 조회
 */
export const getProjectList = async (
  searchValue?: string,
  finderId?: string
) => {
  try {
    const response = await axiosInstance.get(
      `/project/list?projectTitle=${searchValue ?? ""}&finderId=${
        finderId ?? ""
      }`
    );
    if (response.data.isSuccess) {
      return { ...response.data, isSuccess: true };
    } else if (response.data.code === 401) {
      checkErrorType("", 401);
      return { isSuccess: false };
    }
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 프로젝트 상세 조회
 */
export const getProjectDetail = async (projectId: string) => {
  try {
    const response = await axiosInstance.get(`/project/${projectId}`);
    if (response.data.isSuccess) {
      return { ...response.data, isSuccess: true };
    } else if (response.data.code === 401) {
      checkErrorType("", 401);
      return { isSuccess: false };
    }
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 프로젝트 삭제
 */
export const deleteProject = async (projectId: string) => {
  try {
    const response = await axiosInstance.delete(`/project/${projectId}`);
    if (response.data.isSuccess) {
      return { ...response.data, isSuccess: true };
    } else if (response.data.code === 401) {
      checkErrorType("", 401);
      return { isSuccess: false };
    }
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 마커 상세 조회
 */
export const getMarksDetail = async (
  coordinateId: number,
  deviceType: DeviceType
) => {
  try {
    const response = await axiosInstance.get(
      `/marks/${coordinateId}?deviceType=${deviceType}`
    );
    if (response.data.isSuccess) {
      return { ...response.data, isSuccess: true };
    } else if (response.data.code === 401) {
      checkErrorType("", 401);
      return { isSuccess: false };
    }
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 마커 생성
 */
export const createMark = async (formData: CreateMarkerForm) => {
  try {
    const response = await axiosInstance.post("/marks", formData);
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 마커 삭제
 */
export const deleteMark = async (markingId: string) => {
  try {
    const response = await axiosInstance.delete(`/marks/${markingId}`);
    if (response.data.isSuccess) {
      return { ...response.data, isSuccess: true };
    } else if (response.data.code === 401) {
      checkErrorType("", 401);
      return { isSuccess: false };
    }
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 폴더 생성
 */
export const createFolder = async (finderName: string) => {
  try {
    const response = await axiosInstance.post("/finders", {
      finderName,
    });
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 폴더 리스트 조회
 */
export const getFolderList = async () => {
  try {
    const response = await axiosInstance.get("/finders");
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 폴더명 변경
 */
export const updateFolderName = async (
  finderId: string,
  finderName: string
) => {
  try {
    const response = await axiosInstance.patch(`/finders/${finderId}`, {
      finderName,
    });
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 폴더 삭제
 */
export const deleteFolder = async (finderId: string) => {
  try {
    const response = await axiosInstance.delete(`/finders/${finderId}`);
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

/**
 * @description 폴더 이동
 */
export const moveFolder = async (finderId: string, projectId: string) => {
  try {
    const response = await axiosInstance.post(`/project/move-finder`, {
      finderId,
      projectId,
    });
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

export const MEASURE_CONST: {
  [key: string]: {
    a: number;
    b: number;
    c: number;
  };
} = {
  hybrid15setting: {
    a: 4.12,
    b: 0.05,
    c: 4.12,
  },
  hybrid20setting: {
    a: 4.95,
    b: 0.03,
    c: 4.95,
  },
  hybrid15strength: {
    a: 0.19,
    b: 0.04,
    c: 0.19,
  },
  hybrid20strength: {
    a: 0.03,
    b: 0.06,
    c: 0.03,
  },
  setimeta: {
    a: 1.47,
    b: 0.04,
    c: 1.47,
  },
  strength: {
    a: 0.31,
    b: 0.03,
    c: 0.31,
  },
};

export const MEASURE_REFERENCE = {
  initialSettingTime: 3.5,
  finalSetting: 28,
};

export const FORMULA_KEYS = [
  "hybrid15setting",
  "hybrid20setting",
  "hybrid15strength",
  "hybrid20strength",
];

export const DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const SERVER_DATA_FORMAT = "YYYY-MM-DDTHH:mm:ss";

import { MEASURE_CONST } from "../const/measureConst";

export const convertFormulas = (
  hd: number,
  deviceType:
    | "hybrid15setting"
    | "hybrid20setting"
    | "hybrid15strength"
    | "hybrid20strength"
    | "strength"
    | "setimeta"
) => {
  const { a, b, c } = MEASURE_CONST[deviceType];

  return parseFloat((a * Math.exp(b * hd) - c).toFixed(2));
};

export const interpolate = (
  A: number,
  B: number,
  C: number,
  D: number,
  target: number
) => {
  return C + ((D - C) * (target - A)) / (B - A);
};

import React, { useEffect, useState } from "react";
import { CustomTextField } from "../../../../../components/input/textField/CustomTextField";
import { StyledCreateFolderWrap } from "./StyeldCreateFolderWrap";
import CustomButton from "../../../../../components/input/button/CustomButton";
import useDialogStore from "../../../../../stores/useDialogStore";
import { createFolder, updateFolderName } from "../../../../../api/projectApi";
import useAlertStore from "../../../../../stores/useAlertStore";
import useProjectStore from "../../../../../stores/useProjectStore";
import { FolderItemType } from "../../../../../types/common/projectTypes";

export type CreateFolderDialogProps = {
  folder?: FolderItemType;
};

const CreateFolderDialog = ({ folder }: CreateFolderDialogProps) => {
  const { closeDialog } = useDialogStore();
  const { activeAlert } = useAlertStore();
  const { setProjectReloadTrigger } = useProjectStore();
  const [folderName, setFolderName] = useState("");
  const [isError, setIsError] = useState(false);

  const onSaveClick = async () => {
    if (!folderName) {
      setIsError(true);
      return;
    }

    const result = folder
      ? await updateFolderName(folder.finderId, folderName)
      : await createFolder(folderName);

    if (result.isSuccess) {
      activeAlert({
        message: folder
          ? "폴더 이름이 변경되었습니다."
          : "폴더가 생성되었습니다.",
        timeout: 3000,
        type: "success",
      });
      setProjectReloadTrigger();
    }

    closeDialog();
  };

  useEffect(() => {
    if (folder) {
      setFolderName(folder.finderName);
    }
  }, [folder]);

  return (
    <StyledCreateFolderWrap>
      <div className="folder-form-wrap-item">
        <CustomTextField
          id="folderName"
          label="폴더 이름"
          value={folderName}
          handleChange={(value) => {
            setIsError(false);
            setFolderName(value);
          }}
          error={{ isError, errorMessage: "폴더 이름을 입력해주세요." }}
        />
      </div>
      <div className="button-wrap">
        <CustomButton
          label="취소"
          color="primary"
          type="outlined"
          onClick={closeDialog}
          size="medium"
        />
        <CustomButton
          label="저장"
          color="primary"
          type="contained"
          onClick={onSaveClick}
          size="medium"
        />
      </div>
    </StyledCreateFolderWrap>
  );
};

export default CreateFolderDialog;

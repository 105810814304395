import { FolderItemType, ProjectItemType } from "../common/projectTypes";

// FolderItemType인지 확인하는 타입 가드
export const isFolderItem = (
  item: ProjectItemType | FolderItemType
): item is FolderItemType => {
  return (item as FolderItemType).finderName !== undefined;
};

// ProjectItemType인지 확인하는 타입 가드
export const isProjectItem = (
  item: ProjectItemType | FolderItemType
): item is ProjectItemType => {
  return (item as ProjectItemType).projectId !== undefined;
};

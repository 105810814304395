import React, { useCallback, useEffect, useRef, useState } from "react";
import { StyledMeasureHD } from "./StyledMeasureHD";
import { Typography } from "@mui/material";
import CustomButton from "../input/button/CustomButton";
import { SyncLoader } from "react-spinners";
import useBluetoothStore from "../../stores/useBluetoothStore";
import { CustomTextField } from "../input/textField/CustomTextField";

type MeasureHDProps = {
  onMeasureEnd: (value: number) => void;
};

const SERVICE_UUID = "5347aac0-fb94-11e2-a8e4-f23c91aec05e";
const CHARACTERISTIC_UUID = "5347aac7-fb94-11e2-a8e4-f23c91aec05e";

const MeasureHD = ({ onMeasureEnd }: MeasureHDProps) => {
  const { setDevice, setCharacteristic } = useBluetoothStore();
  const [connectionState, setConnectionState] = useState<
    "disconnect" | "connecting" | "connected" | "failed" | "manual"
  >("disconnect");

  const [error, setError] = useState("");
  const [hd, setHd] = useState(0);

  const characteristicRef = useRef(useBluetoothStore.getState().characteristic);
  const deviceRef = useRef(useBluetoothStore.getState().device);

  useEffect(() => {
    // Zustand 상태가 업데이트될 때마다 useRef로 최신 상태를 유지
    const unsubscribe = useBluetoothStore.subscribe((state) => {
      characteristicRef.current = state.characteristic;
    });

    const unsubscribeDevice = useBluetoothStore.subscribe((state) => {
      deviceRef.current = state.device;
    });

    return () => {
      unsubscribe();
      unsubscribeDevice();
    };
  }, []);

  const parseData = (arrayBuffer: ArrayBuffer) => {
    const dataView = new DataView(arrayBuffer);
    const float32Value = dataView.getFloat32(0, true); // Little Endian
    return float32Value;
  };

  const connectToDevice = async () => {
    const navigator: any = window.navigator;

    if (!navigator.bluetooth) {
      setError("Bluetooth 서비스를 지원하지 않는 브라우저입니다.");
      setConnectionState("failed");
      return;
    }

    try {
      setConnectionState("connecting");

      const device = await navigator.bluetooth.requestDevice({
        filters: [{ name: "PosiTector" }],
        optionalServices: [SERVICE_UUID],
      });

      setDevice(device);

      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(SERVICE_UUID);
      const character = await service.getCharacteristic(CHARACTERISTIC_UUID);

      setCharacteristic(character);

      character.removeEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
      await character.startNotifications();
      character.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );

      setConnectionState("connected");
    } catch (err) {
      setConnectionState("failed");
      setError("기기와의 통신 중 오류가 발생했습니다.");
    }
  };

  const reconnectDevice = async () => {
    if (deviceRef.current?.gatt?.connected) {
      await characteristicRef.current.startNotifications();
      characteristicRef.current.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
      setConnectionState("connected");
    } else {
      setCharacteristic(null);
      setDevice(null);
    }
  };

  const handleNotifications = useCallback(
    (event: any) => {
      const value = event.target.value;
      const parsedData = parseData(value.buffer);
      onMeasureEnd(parsedData);
    },
    [onMeasureEnd]
  );

  useEffect(() => {
    if (characteristicRef.current && deviceRef.current) reconnectDevice();

    return () => {
      if (characteristicRef.current && deviceRef.current?.gatt?.connected) {
        characteristicRef.current.removeEventListener(
          "characteristicvaluechanged",
          handleNotifications
        );
        characteristicRef.current.stopNotifications();
      }
    };
  }, [handleNotifications]);

  return (
    <StyledMeasureHD>
      {connectionState === "disconnect" && (
        <div className="empty-HD">
          <Typography>기기를 연결해 주세요.</Typography>
          <div style={{ display: "flex", gap: "8px" }}>
            <CustomButton
              type="outlined"
              color="primary"
              size="small"
              label="직접 입력"
              onClick={() => setConnectionState("manual")}
            />
            <CustomButton
              type="contained"
              color="primary"
              size="small"
              label="기기 연결"
              onClick={connectToDevice}
            />
          </div>
        </div>
      )}
      {connectionState === "connecting" && (
        <div className="connection-loading">
          <Typography>기기 연결 중입니다. 잠시만 기다려 주세요.</Typography>
          <SyncLoader color="#8a9ba8" size={20} speedMultiplier={0.7} />
        </div>
      )}
      {connectionState === "connected" && (
        <div className="connection-success">
          <Typography>연결되었습니다. HD를 측정해 주세요.</Typography>
        </div>
      )}
      {connectionState === "manual" && (
        <div className="hd-manual">
          <Typography>측정한 HD를 입력해 주세요.</Typography>
          <div className="hd-manual-input">
            <CustomTextField
              id="manualHd"
              type="number"
              label="HD"
              value={hd}
              handleChange={(value: string) => setHd(parseFloat(value))}
            />
            <CustomButton
              type="contained"
              color="primary"
              size="small"
              label="저장"
              onClick={() => {
                if (isNaN(hd)) onMeasureEnd(0);
                else onMeasureEnd(hd);
              }}
            />
          </div>
        </div>
      )}
      {connectionState === "failed" && (
        <div className="connection-error">
          <Typography>{error}</Typography>
          <CustomButton
            type="contained"
            color="primary"
            size="small"
            label="재시도"
            onClick={() => setConnectionState("disconnect")}
          />
        </div>
      )}
    </StyledMeasureHD>
  );
};

export default MeasureHD;

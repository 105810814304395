import {
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
} from "@mui/material";
import React, { ReactNode, useMemo } from "react";
import { SpeedDialActionType } from "../../../../types/common/commonTypes";

type CustomFloatingButtonProps = {
  icon: ReactNode;
  size: "small" | "medium" | "large";
  color: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  label?: string;
  className?: string;
  disabled?: boolean;
  actions?: SpeedDialActionType[];
  tooltip?: {
    label: string;
    direction:
      | "bottom"
      | "left"
      | "right"
      | "top"
      | "bottom-end"
      | "bottom-start"
      | "left-end"
      | "left-start"
      | "right-end"
      | "right-start"
      | "top-end"
      | "top-start";
  };
  onClick?: (e: React.MouseEvent) => void;
};

const CustomFloatingButton = ({
  icon,
  label,
  className,
  size,
  color,
  actions,
  onClick,
  tooltip,
}: CustomFloatingButtonProps) => {
  const fab = useMemo(() => {
    if (actions) {
      return (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map(
            (action) =>
              !action.hidden && (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.tooltipTitle}
                  // tooltipOpen
                  onClick={action.onClick}
                />
              )
          )}
        </SpeedDial>
      );
    } else {
      return (
        <Fab
          className={className}
          size={size}
          color={color}
          aria-label="label"
          variant={label ? "extended" : "circular"}
          onClick={onClick}
        >
          {icon}
          {label && label}
        </Fab>
      );
    }
  }, [icon, onClick, tooltip, actions]);

  return tooltip ? (
    <Tooltip title={tooltip.label} placement={tooltip.direction} arrow>
      {fab}
    </Tooltip>
  ) : (
    fab
  );
};

export default CustomFloatingButton;

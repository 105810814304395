import { DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import CustomButton from "../../input/button/CustomButton";
import { StyledMeasurePopup } from "./StyledMeasurePopup";
import { CustomSelect } from "../../input/select/CustomSelect";
import CustomTable from "../../table/CustomTable";
import dayjs from "dayjs";
import {
  DetectionRangeType,
  DeviceType,
  MarkerDataType,
} from "../../../types/common/commonTypes";
import CustomTimePicker from "../../input/timePicker/CustomTimePicker";
import useDialogStore from "../../../stores/useDialogStore";
import MeasureHD from "../../measureHD/MeasureHD";

type MeasurePopupProps = {
  onCancel: () => void;
  onInfoChange: (data: Partial<MarkerDataType>) => void;
};

const DEVICE_OPTION_LIST = [
  { label: "하이브리드", value: "hybrid" },
  { label: "스트렝스", value: "strength" },
  { label: "세티메타", value: "setimeta" },
];

const THICKNESS_OPTION_LIST = [
  { label: "1.5mm", value: "15" },
  { label: "2mm", value: "20" },
];

/**
 * @description 실제로 MPa를 구하기 위해 필요한 기본 정보를 입력받고 HD를 측정하는 팝업
 * @param onCancel 측정 정보 팝업 닫기
 * @param onInfoChange 측정 정보 변경
 * @returns
 */
const MeasurePopup = ({ onCancel, onInfoChange }: MeasurePopupProps) => {
  const { openDialog, closeDialog } = useDialogStore();
  const [measuredValues, setMeasuredValues] = useState<{
    pouringTime: string;
    deviceType: DeviceType;
    detectionRange: DetectionRangeType;
    thickness: string;
  }>({
    pouringTime: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
    deviceType: "hybrid",
    detectionRange: "setting",
    thickness: "15",
  });

  /**
   * @description 측정 정보 변경
   * @param key 변경할 측정 정보 키
   * @param value 변경할 측정 정보 값
   */
  const handleValueChange = (
    key: keyof MarkerDataType,
    value: string | null
  ) => {
    if (value !== null) {
      if (key === "deviceType" && value !== "hybrid") {
        if (value === "strength") {
          setMeasuredValues({
            ...measuredValues,
            [key]: value,
            detectionRange: "strength",
          });
        } else if (value === "setimeta") {
          setMeasuredValues({
            ...measuredValues,
            [key]: value,
            detectionRange: "setting",
          });
        }
      } else {
        setMeasuredValues({ ...measuredValues, [key]: value });
      }
    }
  };

  /**
   * @description 측정 종료 시 측정 정보 저장
   * @param value 측정 값
   */
  const onMeasureEnd = (value: number) => {
    closeDialog();
    onInfoChange({
      ...measuredValues,
      concreteHd: value,
    });
  };

  /**
   * @description 측정 범위 옵션 리스트
   */
  const measureOptionList = useMemo(() => {
    if (measuredValues.deviceType === "strength")
      return [{ label: "강도", value: "strength" }];
    else if (measuredValues.deviceType === "setimeta")
      return [{ label: "응결", value: "setting" }];
    else
      return [
        { label: "응결", value: "setting" },
        { label: "강도", value: "strength" },
        { label: "응결/강도", value: "hybrid" },
      ];
  }, [measuredValues.deviceType]);

  /**
   * @description 측정 정보 테이블 데이터
   */
  const rows = useMemo(() => {
    const rowList = [
      {
        name: "타설 시간",
        key: "pouringTime",
        rowRender: (
          <CustomTimePicker
            id="pouringTime"
            width="200px"
            value={measuredValues.pouringTime}
            handleChange={(value) => handleValueChange("pouringTime", value)}
          />
        ),
      },
      {
        name: "기기 유형",
        key: "deviceType",
        rowRender: (
          <CustomSelect
            id="tool-type"
            width="200px"
            value={measuredValues.deviceType}
            handleChange={(value) =>
              handleValueChange("deviceType", value as "hybrid" | "strength")
            }
            optionList={DEVICE_OPTION_LIST}
          />
        ),
      },
      {
        name: "측정 범위",
        key: "detectionRange",
        rowRender: (
          <CustomSelect
            id="measure-type"
            width="200px"
            value={measuredValues.detectionRange}
            readonly={measuredValues.deviceType !== "hybrid"}
            handleChange={(value) =>
              handleValueChange(
                "detectionRange",
                value as "setting" | "strength"
              )
            }
            optionList={measureOptionList}
          />
        ),
      },
    ];

    if (measuredValues.deviceType === "hybrid") {
      rowList.push({
        name: "두께",
        key: "thickness",
        rowRender: (
          <CustomSelect
            id="thickness"
            width="200px"
            value={measuredValues.thickness}
            handleChange={(value) => handleValueChange("thickness", value)}
            optionList={THICKNESS_OPTION_LIST}
          />
        ),
      });
    }

    return rowList;
  }, [measuredValues]);

  return (
    <>
      <DialogContent dividers sx={{ backgroundColor: "#f0f0f0" }}>
        <StyledMeasurePopup>
          <Typography style={{ marginBottom: "8px" }} variant="h6">
            측정 정보
          </Typography>
          <CustomTable rows={rows} />
        </StyledMeasurePopup>
      </DialogContent>
      <DialogActions>
        <CustomButton
          label="닫기"
          type="outlined"
          color="primary"
          size="large"
          onClick={onCancel}
        />
        <CustomButton
          label="다음"
          width="100px"
          type="contained"
          color="primary"
          size="large"
          onClick={() => {
            openDialog({
              size: "large",
              state: "info",
              title: "HD 측정",
              content: <MeasureHD onMeasureEnd={onMeasureEnd} />,
            });
          }}
        />
      </DialogActions>
    </>
  );
};

export default MeasurePopup;

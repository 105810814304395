import { useNavigate } from "react-router-dom";
import useAuthStore from "../stores/useAuthStore";
import { LoginReqType } from "../types/api/userApiType";
import { requestLogin } from "../api/userApi";
import useProjectStore from "../stores/useProjectStore";

const useAuth = () => {
  const navigate = useNavigate();
  const { setSelectedFolderData } = useProjectStore();
  const { setIsLogin, setUserInfo, resetAuthStore } = useAuthStore();

  const getAccessToken = () => {
    return window.localStorage.getItem("ACCESS_TOKEN") ?? "";
  };

  const login = async (
    { loginId, password }: LoginReqType,
    noNavigate = false
  ) => {
    const result = await requestLogin({ loginId, password });

    if (result.isSuccess) {
      setIsLogin(true);
      setUserInfo({ loginId: result.loginId, userId: result.userId });
      window.localStorage.setItem("ACCESS_TOKEN", result.ACCESS_TOKEN);

      if (!noNavigate) navigate("/");
    }
  };

  const logout = async (isRefresh = true) => {
    window.localStorage.removeItem("ACCESS_TOKEN");
    resetAuthStore();
    setSelectedFolderData(null);

    if (isRefresh) window.location.reload();
  };

  const checkLogin = () => {
    const token = getAccessToken();
    if (token) return true;
    else return false;
  };

  return {
    login,
    logout,
    checkLogin,
  };
};

export default useAuth;

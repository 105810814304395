import React, { useEffect, useRef, useState } from "react";
import { StyledEditPage } from "./StyledDetailPage";
import ImagePreview from "../../components/imagePreview/ImagePreview";
import { Divider, Typography } from "@mui/material";
import CustomButton from "../../components/input/button/CustomButton";

import { useNavigate, useParams } from "react-router-dom";
import useProjectStore from "../../stores/useProjectStore";
import useAlertStore from "../../stores/useAlertStore";
import dayjs from "dayjs";
import { getProjectDetail } from "../../api/projectApi";
import useAuthStore from "../../stores/useAuthStore";
import { loadImage } from "../../utils/commonUtils";

const DetailPage = () => {
  const { userInfo } = useAuthStore();
  const { projectId } = useParams();
  const { activeAlert } = useAlertStore();
  const { projectDetail, setProjectDetail } = useProjectStore();
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");

  const fetchProjectDetail = async () => {
    if (!projectId) {
      navigate("/");
    } else {
      const result = await getProjectDetail(projectId);

      try {
        if (result.isSuccess && result.resultList) {
          const imgUrl = await loadImage(result.resultList.imgFileId);

          if (!imgUrl) {
            throw Error();
          }

          setImageUrl(imgUrl);
          setProjectDetail({ ...result.resultList, imgUrl: imgUrl });
        } else {
          throw Error();
        }
      } catch (e) {
        activeAlert({
          type: "error",
          message: "상세 정보를 불러오지 못 했습니다. 다시 시도해 주세요.",
          timeout: 3000,
        });
      }
    }
  };

  const onEditClick = () => {
    if (projectDetail) navigate(`/edit/${projectDetail.projectId}`);
  };

  useEffect(() => {
    fetchProjectDetail();
  }, []);

  return (
    <StyledEditPage>
      <div className="edit-content-wrap">
        <Typography variant="h6" sx={{ wordBreak: "break-all" }}>
          {projectDetail?.projectTitle}
        </Typography>
        <div className="created-info">
          <Typography variant="body2" align="right">
            {projectDetail?.createdId}
          </Typography>
          <Typography variant="body2" align="right">
            {dayjs(projectDetail?.createdDate).format("YYYY-MM-DD HH:mm")}
          </Typography>
        </div>
        <div className="file-wrap">
          {imageUrl && (
            <ImagePreview
              file={null}
              coordinateList={projectDetail?.coordinateList ?? []}
              isDetail
              previewUrl={imageUrl}
              isMarkerAble={false}
              handleClick={() => {}}
            />
          )}
        </div>
        <Typography sx={{ wordBreak: "break-all" }}>
          {projectDetail?.projectDescription}
        </Typography>
      </div>
      {userInfo.loginId === projectDetail?.createdId && (
        <>
          <Divider />
          <div className="edit-button-wrap">
            <CustomButton
              width="100px"
              type="contained"
              color="primary"
              size="large"
              label="수정"
              onClick={onEditClick}
            />
          </div>
        </>
      )}
    </StyledEditPage>
  );
};

export default DetailPage;

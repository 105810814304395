import { List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SkeletonItem from "../SkeletonItem.tsx/SkeletonItem";
import CustomListItem from "../listItem/CustomListItem";
import { deleteFolder, deleteProject } from "../../../api/projectApi";
import useProjectStore from "../../../stores/useProjectStore";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FolderIcon from "@mui/icons-material/Folder";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  FolderItemType,
  ProjectItemType,
} from "../../../types/common/projectTypes";
import ListSecondary from "./secondary/ListSecondary";
import useAuthStore from "../../../stores/useAuthStore";
import useLoadingStore from "../../../stores/useLoadingStore";
import useDialogStore from "../../../stores/useDialogStore";
import { StyledListPrimary } from "./StyledCustomList";
import useAlertStore from "../../../stores/useAlertStore";
import {
  isFolderItem,
  isProjectItem,
} from "../../../types/typeguard/typeguard";

type CustomListType = {
  isSelectedMode: boolean;
  loading: boolean;
  selectedProjectList: string[];
  loadProjectList: () => Promise<any>;
  loadProjectListByFolder: (folder: FolderItemType) => Promise<any>;
  openCreateFolderDialog: (folder?: FolderItemType) => void;
  handleSelectProject: (projectId: string) => void;
};

const CustomList = ({
  isSelectedMode,
  loading,
  selectedProjectList,
  loadProjectList,
  loadProjectListByFolder,
  openCreateFolderDialog,
  handleSelectProject,
}: CustomListType) => {
  const { userInfo } = useAuthStore();
  const { activeAlert } = useAlertStore();
  const { setIsLoading } = useLoadingStore();
  const { openDialog, closeDialog } = useDialogStore();
  const {
    projectList,
    projectReloadTrigger,
    selectedFolderData,
    setProjectReloadTrigger,
  } = useProjectStore();
  const navigate = useNavigate();

  const onListItemClick = (
    item: ProjectItemType | FolderItemType,
    isEdit?: boolean
  ) => {
    if (isSelectedMode) {
      if (isProjectItem(item)) handleSelectProject(item.projectId);
    } else {
      if (isProjectItem(item)) {
        navigate(`/${isEdit ? "edit" : "detail"}/${item.projectId}`);
      } else if (isFolderItem(item)) {
        loadProjectListByFolder(item);
      }
    }
  };

  const removeProject = async (projectId: string) => {
    setIsLoading(true);

    const result = await deleteProject(projectId);

    if (result.isSuccess) {
      await setProjectReloadTrigger();
      activeAlert({
        type: "success",
        message: "프로젝트가 삭제되었습니다.",
        timeout: 3000,
      });
    }

    setIsLoading(false);
  };

  const removeFolder = async (folderId: string) => {
    setIsLoading(true);

    const result = await deleteFolder(folderId);

    if (result.isSuccess) {
      await loadProjectList();
      activeAlert({
        type: "success",
        message: "폴더가 삭제되었습니다.",
        timeout: 3000,
      });
    }

    setIsLoading(false);
  };

  const onDeleteClick = (projectId: string, type: "project" | "folder") => {
    if (type === "folder") {
      openDialog({
        size: "small",
        title: "삭제",
        state: "info",
        content: "폴더를 삭제하시겠습니까?",
        button: {
          label: "확인",
          onClick: () => {
            removeFolder(projectId);
            closeDialog();
          },
        },
        secondButton: {
          label: "취소",
          onClick: () => closeDialog(),
        },
      });
    } else {
      openDialog({
        size: "small",
        title: "삭제",
        state: "info",
        content: "프로젝트를 삭제하시겠습니까?",
        button: {
          label: "확인",
          onClick: () => {
            removeProject(projectId);
            closeDialog();
          },
        },
        secondButton: {
          label: "취소",
          onClick: () => closeDialog(),
        },
      });
    }
  };

  useEffect(() => {
    if (selectedFolderData) {
      loadProjectListByFolder(selectedFolderData);
    } else {
      loadProjectList();
    }
  }, []);

  useEffect(() => {
    if (projectReloadTrigger > 0) {
      if (selectedFolderData) {
        loadProjectListByFolder(selectedFolderData);
      } else {
        loadProjectList();
      }
    }
  }, [projectReloadTrigger]);

  return (
    <List>
      {!projectList || projectList.length === 0 ? (
        <Typography
          variant="subtitle2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          생성된 프로젝트가 없습니다.
        </Typography>
      ) : (
        projectList.map((item, index) => (
          <CustomListItem
            key={isFolderItem(item) ? item.finderId : item.projectId}
            icon={
              isFolderItem(item) ? (
                <FolderIcon color="primary" />
              ) : (
                <InsertDriveFileIcon color="secondary" />
              )
            }
            primary={
              <StyledListPrimary>
                <span className="project-title">
                  {isFolderItem(item) ? item.finderName : item.projectTitle}
                </span>
                <span className="created-id">
                  {isFolderItem(item) ? "" : item.createdId}
                </span>
              </StyledListPrimary>
            }
            secondary={<ListSecondary item={item} />}
            isLastItem={!loading && index === projectList.length - 1}
            buttonDisabled={
              isProjectItem(item) && userInfo.loginId !== item.createdId
            }
            btnList={
              !isSelectedMode
                ? [
                    {
                      label: "수정",
                      icon: <EditIcon />,
                      autoClose: true,
                      onClick: () =>
                        isFolderItem(item)
                          ? openCreateFolderDialog(item)
                          : onListItemClick(item, true),
                    },
                    {
                      label: "삭제",
                      icon: <DeleteOutlineIcon />,
                      autoClose: true,
                      onClick: () =>
                        onDeleteClick(
                          isFolderItem(item) ? item.finderId : item.projectId,
                          isFolderItem(item) ? "folder" : "project"
                        ),
                    },
                  ]
                : undefined
            }
            customButton={
              isSelectedMode && isProjectItem(item) ? (
                selectedProjectList.includes(item.projectId) ? (
                  <CheckBoxIcon color="primary" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" />
                )
              ) : undefined
            }
            onClick={() => onListItemClick(item)}
          />
        ))
      )}
      {loading && (
        <ListItem>
          <SkeletonItem variantList={["h3", "body1"]} />
        </ListItem>
      )}
    </List>
  );
};

export default CustomList;

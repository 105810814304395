import styled from "styled-components";

export const StyledSignUpPage = styled.div`
  width: 100%;
  height: 100%;

  .sign-up-form-wrap {
    width: 100%;
    height: calc(100% - 64px);
    padding: 36px 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    .sign-up-form-wrap-item {
      max-width: 350px;
      width: 80%;
      height: 68px;
      flex-shrink: 0;
      &.group-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }

  .sign-up-button-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    background-color: #ffffff;
  }
`;

import styled from "styled-components";

export const StyledLoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .login-form-wrap {
    max-width: 350px;
    min-width: 100px;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 0.8px;

    .text-field-wrap {
      width: 100%;
      height: 64px;
    }
  }
  .sign-up-btn {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
`;

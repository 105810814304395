import styled from "styled-components";

export const StyledListPrimary = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .project-title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 65px);
  }
  .created-id {
    font-size: 10px;
  }
`;

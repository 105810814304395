import React, { useState } from "react";
import { StyledAddGroupDialog } from "./StyledAddGroupDialog";
import { Typography } from "@mui/material";
import { CustomTextField } from "../../../components/input/textField/CustomTextField";
import CustomButton from "../../../components/input/button/CustomButton";
import { createGroup } from "../../../api/userApi";
import useAlertStore from "../../../stores/useAlertStore";
import useDialogStore from "../../../stores/useDialogStore";

type AddGroupDialogProps = {
  fetchGroupList: () => void;
};

const AddGroupDialog = ({ fetchGroupList }: AddGroupDialogProps) => {
  const { closeDialog } = useDialogStore();
  const { activeAlert } = useAlertStore();
  const [password, setPassword] = useState("");
  const [isPassed, setIsPassed] = useState(false);
  const [isError, setIsError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [groupName, setGroupName] = useState("");

  const checkIsPassed = () => {
    if (password === process.env.REACT_APP_ADMIN_PASS) {
      setIsPassed(true);
    } else {
      setIsError({
        isError: true,
        errorMessage: "비밀번호가 일치하지 않습니다.",
      });
    }
  };

  const addGroup = async () => {
    const result = await createGroup({ groupName });

    if (result.isSuccess) {
      await fetchGroupList();
      activeAlert({
        type: "success",
        message: "그룹이 추가되었습니다.",
        timeout: 3000,
      });
    }
    closeDialog();
  };

  return (
    <StyledAddGroupDialog>
      <div className="password-wrap">
        {isPassed ? (
          <>
            <p>그룹 이름을 입력해 주세요.</p>
            <div className="password-input-wrap">
              <CustomTextField
                id="password"
                value={groupName}
                handleChange={setGroupName}
                placeholder="그룹 이름"
              />
            </div>
          </>
        ) : (
          <>
            <p>그룹을 추가하시려면 관리자 비밀번호를 입력해 주세요.</p>
            <div className="password-input-wrap">
              <CustomTextField
                id="password"
                value={password}
                handleChange={(value) => {
                  setPassword(value);
                  setIsError({
                    isError: false,
                    errorMessage: "",
                  });
                }}
                placeholder="관리자 비밀번호"
                error={isError}
              />
            </div>
          </>
        )}
      </div>
      <div className="button-wrap">
        <CustomButton
          label="취소"
          onClick={closeDialog}
          type="outlined"
          color="primary"
          size="small"
        />
        <CustomButton
          label={isPassed ? "추가" : "확인"}
          onClick={isPassed ? addGroup : checkIsPassed}
          type="contained"
          color="primary"
          size="small"
        />
      </div>
    </StyledAddGroupDialog>
  );
};

export default AddGroupDialog;

import styled from "styled-components";

export const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

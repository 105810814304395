import { createTheme, ThemeProvider } from "@mui/material";
import React, { ReactNode } from "react";

const theme = createTheme({
  palette: {
    primary: {
      light: "#8FBCE6", // 부드럽고 밝은 하늘색
      main: "#497DB8", // 세련된 기본 블루
      dark: "#2D5B89", // 깊고 안정감 있는 다크 블루
    },
    secondary: {
      light: "#F4D1A6", // 따뜻한 밝은 오렌지
      main: "#F4A261", // 활기 있는 오렌지
      dark: "#C77B3F", // 깊이 있는 다크 오렌지
    },
    background: {
      default: "#F8FAFC", // 밝고 깔끔한 배경
      paper: "#FFFFFF", // 카드 및 섹션 배경
    },
    text: {
      primary: "#4F5D75", // 부드럽고 세련된 블루-그레이
      secondary: "#8A9BA8", // 밝은 회색-블루 텍스트
    },
  },
  typography: {
    fontFamily: "Pretendard, sans-serif", // 기본 폰트
  },
});

type ThemeProps = {
  children: ReactNode;
};

const Theme = ({ children }: ThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;

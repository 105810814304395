import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
} from "@mui/material";
import React, { useMemo } from "react";
import { ButtonType } from "../../types/common/commonTypes";

type MoreMenuProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  buttonList: ButtonType[];
  id: string;
  direction:
    | "top-left"
    | "top"
    | "top-right"
    | "right"
    | "bottom-right"
    | "bottom"
    | "bottom-left"
    | "left";
  maxHeight?: string;
  width?: string;
  handleClose: () => void;
};

const MoreMenu = ({
  anchorEl,
  open,
  buttonList,
  id,
  direction,
  maxHeight = "200px",
  width = "70px",
  handleClose,
}: MoreMenuProps) => {
  const anchorOrigin = useMemo((): PopoverOrigin => {
    switch (direction) {
      case "top-left":
        return { horizontal: "left", vertical: "top" };
      case "top":
        return { horizontal: "center", vertical: "top" };
      case "top-right":
        return { horizontal: "right", vertical: "top" };
      case "right":
        return { horizontal: "right", vertical: "center" };
      case "bottom-right":
        return { horizontal: "right", vertical: "bottom" };
      case "bottom":
        return { horizontal: "center", vertical: "bottom" };
      case "bottom-left":
        return { horizontal: "left", vertical: "bottom" };
      case "left":
        return { horizontal: "left", vertical: "center" };
    }
  }, [direction]);

  const transformOrigin = useMemo((): PopoverOrigin => {
    switch (direction) {
      case "top-left":
        return { horizontal: "right", vertical: "bottom" };
      case "top":
        return { horizontal: "center", vertical: "bottom" };
      case "top-right":
        return { horizontal: "left", vertical: "bottom" };
      case "right":
        return { horizontal: "left", vertical: "top" };
      case "bottom-right":
        return { horizontal: "left", vertical: "top" };
      case "bottom":
        return { horizontal: "center", vertical: "top" };
      case "bottom-left":
        return { horizontal: "right", vertical: "top" };
      case "left":
        return { horizontal: "right", vertical: "top" };
    }
  }, [direction]);

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      slotProps={{
        paper: {
          style: {
            maxHeight: maxHeight,
            minWidth: width,
          },
        },
      }}
    >
      {buttonList.map((button, index) =>
        button.hidden ? null : (
          <MenuItem
            key={index}
            onClick={() => {
              button.onClick();
              if (button.autoClose) handleClose();
            }}
            sx={{ paddingY: "8px" }}
            divider={index !== buttonList.length - 1}
          >
            {button.icon && <ListItemIcon>{button.icon}</ListItemIcon>}
            {button.label && <ListItemText>{button.label}</ListItemText>}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default MoreMenu;

import styled from "styled-components";

export const StyledSecondary = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .description {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    width: calc(100% - 65px);
  }
  .created-date {
    width: 55px;
    font-size: 10px;
    text-align: right;
  }
`;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { StyledSignUpPage } from "./StyledSignUpPage";
import { Typography } from "@mui/material";
import CustomButton from "../../components/input/button/CustomButton";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../../components/input/textField/CustomTextField";
import BadgeIcon from "@mui/icons-material/Badge";
import HttpsIcon from "@mui/icons-material/Https";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Groups2Icon from "@mui/icons-material/Groups2";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import useAlertStore from "../../stores/useAlertStore";
import useDialogStore from "../../stores/useDialogStore";
import { createUser, getGroupList } from "../../api/userApi";
import { GroupType } from "../../types/common/userTypes";
import { CustomSelect } from "../../components/input/select/CustomSelect";
import AddGroupDialog from "./dialog/AddGroupDialog";

const SignUpPage = () => {
  const navigate = useNavigate();
  const { activeAlert } = useAlertStore();
  const { openDialog, closeDialog } = useDialogStore();

  const groupWrapRef = useRef<HTMLDivElement>(null);
  const [groupList, setGroupList] = useState<
    { label: string; value: string }[]
  >([]);

  // 밸리데이션 관련
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [checkPassword, setCheckPassword] = useState("");
  const [errorInfo, setErrorInfo] = useState({
    loginId: {
      isError: false,
      errorMessage: "",
    },
    password: {
      isError: false,
      errorMessage: "",
    },
    username: {
      isError: false,
      errorMessage: "",
    },
    groupId: {
      isError: false,
      errorMessage: "",
    },
    mobile: {
      isError: false,
      errorMessage: "",
    },
    email: {
      isError: false,
      errorMessage: "",
    },
  });
  const [signUpInfo, setSignUpInfo] = useState({
    loginId: "",
    password: "",
    username: "",
    mobile: "",
    email: "",
    groupId: "",
  });

  const handleChange = (id: string, value: string) => {
    let inputValue = value;
    if (id === "mobile") {
      // 숫자만 추출
      const numbers = value.replace(/[^0-9]/g, "");

      // 최대 11자리로 제한
      const limitedNumbers = numbers.slice(0, 11);

      // 형식에 맞게 하이픈 추가
      if (limitedNumbers.length <= 3) {
        inputValue = limitedNumbers;
      } else if (limitedNumbers.length <= 7) {
        inputValue = `${limitedNumbers.slice(0, 3)}-${limitedNumbers.slice(3)}`;
      } else {
        inputValue = `${limitedNumbers.slice(0, 3)}-${limitedNumbers.slice(
          3,
          7
        )}-${limitedNumbers.slice(7)}`;
      }
    } else {
      inputValue = value;
    }
    setErrorInfo((prev) => ({
      ...prev,
      [id]: { isError: false, errorMessage: "" },
    }));
    setSignUpInfo((prev) => ({ ...prev, [id]: inputValue }));
  };

  const handlePasswordCheck = (value: string) => {
    setCheckPassword(value);

    if (signUpInfo.password === value) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
    }
  };

  const handleSignUp = () => {
    // 모든 필드가 입력되었는지 확인
    if (
      !signUpInfo.loginId ||
      !signUpInfo.password ||
      !signUpInfo.groupId ||
      !signUpInfo.username ||
      !signUpInfo.mobile ||
      !signUpInfo.email
    ) {
      Object.keys(signUpInfo).forEach((key) => {
        if (!signUpInfo[key as keyof typeof signUpInfo]) {
          setErrorInfo((prev) => ({
            ...prev,
            [key]: { isError: true, errorMessage: "필수 항목입니다." },
          }));
        }
      });
      return;
    }

    if (!isPasswordMatch) {
      setIsPasswordMatch(false);
      return;
    }

    // 아이디 유효성 검사
    if (!isValidId()) {
      setErrorInfo((prev) => ({
        ...prev,
        loginId: {
          isError: true,
          errorMessage:
            "아이디는 영문, 숫자, 특수문자(_-)를 포함할 수 있고, 4자 이상이어야 합니다.",
        },
      }));
      return;
    }

    // 비밀번호 유효성 검사
    if (!isValidPassword()) {
      setErrorInfo((prev) => ({
        ...prev,
        password: {
          isError: true,
          errorMessage:
            "비밀번호는 영문과 숫자를 포함하여 8자 이상이어야 합니다.",
        },
      }));
      return;
    }

    // 이메일 유효성 검사
    if (!isValidEmail()) {
      setErrorInfo((prev) => ({
        ...prev,
        email: {
          isError: true,
          errorMessage: "올바른 이메일 형식이 아닙니다.",
        },
      }));
      return;
    }

    openDialog({
      size: "small",
      title: "회원가입",
      state: "info",
      content: "회원가입을 진행하시겠습니까?",
      button: {
        label: "확인",
        onClick: onSaveClick,
      },
      secondButton: {
        label: "취소",
        onClick: () => closeDialog(),
      },
    });
  };

  const onSaveClick = async () => {
    const result = await createUser(signUpInfo);
    if (result.isSuccess) {
      openDialog({
        size: "small",
        title: "회원가입",
        state: "success",
        content: "회원가입이 완료되었습니다! 로그인 페이지로 이동합니다.",
        button: {
          label: "확인",
          onClick: () => {
            closeDialog();
            navigate("/login");
          },
        },
      });
    } else {
      activeAlert({
        type: "error",
        message: "회원가입에 실패했습니다. 다시 시도해 주세요.",
        timeout: 3000,
      });
      closeDialog();
    }
  };

  const onAddGroupClick = () => {
    openDialog({
      size: "small",
      title: "그룹 추가",
      state: "info",
      content: <AddGroupDialog fetchGroupList={fetchGroupList} />,
    });
  };

  const fetchGroupList = async () => {
    const result = await getGroupList();
    if (result.isSuccess && result.resultList) {
      setGroupList(
        result.resultList.map((item: GroupType) => ({
          label: item.groupName,
          value: item.groupId,
        }))
      );
    }
  };

  const isValidId = (): boolean => {
    const idRegex = /^[a-zA-Z0-9_-]{4,}$/;
    return idRegex.test(signUpInfo.loginId);
  };

  const isValidPassword = (): boolean => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    return passwordRegex.test(signUpInfo.password);
  };

  const isValidEmail = (): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(signUpInfo.email);
  };

  useEffect(() => {
    fetchGroupList();
  }, []);

  return (
    <StyledSignUpPage>
      <div className="sign-up-form-wrap">
        <Typography variant="h6" sx={{ marginBottom: "16px" }}>
          가입 정보
        </Typography>
        <div className="sign-up-form-wrap-item">
          <CustomTextField
            id="loginId"
            icon={<BadgeIcon />}
            value={signUpInfo.loginId}
            handleChange={(value) => handleChange("loginId", value)}
            placeholder="아이디"
            error={errorInfo.loginId}
          />
        </div>
        <div className="sign-up-form-wrap-item">
          <CustomTextField
            id="password"
            icon={<HttpsIcon />}
            value={signUpInfo.password}
            handleChange={(value) => {
              handleChange("password", value);
              if (checkPassword === value) {
                setIsPasswordMatch(true);
              } else {
                setIsPasswordMatch(false);
              }
            }}
            placeholder="패스워드"
            type="password"
            error={errorInfo.password}
          />
        </div>
        <div className="sign-up-form-wrap-item">
          <CustomTextField
            id="passwordCheck"
            icon={<HttpsIcon />}
            value={checkPassword}
            handleChange={handlePasswordCheck}
            placeholder="패스워드 확인"
            type="password"
            error={{
              isError: !isPasswordMatch,
              errorMessage: "패스워드가 일치하지 않습니다.",
            }}
          />
        </div>
        <div className="sign-up-form-wrap-item">
          <CustomTextField
            id="username"
            icon={<PersonIcon />}
            value={signUpInfo.username}
            handleChange={(value) => handleChange("username", value)}
            placeholder="이름"
            error={errorInfo.username}
          />
        </div>
        <div ref={groupWrapRef} className="sign-up-form-wrap-item group-wrap">
          <CustomSelect
            id="group"
            value={signUpInfo.groupId}
            icon={<Groups2Icon />}
            optionList={groupList}
            placeholder="그룹"
            handleChange={(value) => handleChange("groupId", value)}
            width={
              groupWrapRef.current?.clientWidth
                ? `${groupWrapRef.current?.clientWidth - 100}px`
                : undefined
            }
            error={errorInfo.groupId}
          />
          <CustomButton
            label="그룹 추가"
            type="contained"
            color="primary"
            size="medium"
            onClick={onAddGroupClick}
          />
        </div>
        <div className="sign-up-form-wrap-item">
          <CustomTextField
            id="mobile"
            icon={<PhoneAndroidIcon />}
            value={signUpInfo.mobile}
            handleChange={(value) => handleChange("mobile", value)}
            placeholder="010-0000-0000"
            error={errorInfo.mobile}
          />
        </div>
        <div className="sign-up-form-wrap-item">
          <CustomTextField
            id="email"
            icon={<AlternateEmailIcon />}
            value={signUpInfo.email}
            handleChange={(value) => handleChange("email", value)}
            placeholder="example@example.com"
            error={errorInfo.email}
          />
        </div>
      </div>
      <div className="sign-up-button-wrap">
        <CustomButton
          width="100px"
          type="outlined"
          color="primary"
          size="large"
          label="취소"
          onClick={() => navigate(-1)}
        />
        <CustomButton
          width="120px"
          type="contained"
          color="primary"
          size="large"
          label="회원가입"
          onClick={handleSignUp}
        />
      </div>
    </StyledSignUpPage>
  );
};

export default SignUpPage;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, DialogActions, DialogContent } from "@mui/material";
import { ButtonProps } from "../../stores/useDialogStore";
import CustomButton from "../input/button/CustomButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type CustomPopupProps = {
  isOpen: boolean;
  children: React.ReactNode;
  footerChildren?: React.ReactNode;
  title: string;
  isFullScreen?: boolean;
  rightChildren?: React.ReactNode;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
};

const CustomPopup = ({
  isOpen,
  title,
  children,
  isFullScreen = true,
  footerChildren,
  rightChildren,
  setIsOpen,
  onClose,
}: CustomPopupProps) => {
  const handleClose = (event: React.MouseEvent, reason: string) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      if (onClose) onClose();
    }

    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={isFullScreen}
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setIsOpen(false);
                if (onClose) onClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>

            {rightChildren && (
              <Box sx={{ marginLeft: "auto" }}>{rightChildren}</Box>
            )}
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    </React.Fragment>
  );
};

export default CustomPopup;

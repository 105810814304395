import styled from "styled-components";

export const StyledMeasureHD = styled.div`
  width: 100%;

  .empty-HD {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .connection-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .connection-success {
    display: flex;
    justify-content: center;
  }
  .connection-error {
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .hd-manual {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .hd-manual-input {
      display: flex;
      gap: 16px;
    }
  }
`;

import { getFile } from "../api/fileApi";
import { DetectionRangeType, DeviceType } from "../types/common/commonTypes";
import domtoimage from "dom-to-image-more";
import html2canvas from "html2canvas";

/**
 * @description 파일 확장자 추출
 * @param file 파일
 * @returns 파일 확장자
 */
export const getFileExtension = (file: File) => {
  if (!file) return "";
  // 파일 이름에서 확장자를 추출
  const fileName = file.name;
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
};

/**
 * @description 기기 유형 라벨 추출
 * @param deviceType 기기 유형
 * @returns 기기 유형 라벨
 */
export const getDeviceTypeLabel = (deviceType: DeviceType) => {
  switch (deviceType) {
    case "hybrid":
      return "하이브리드";
    case "strength":
      return "스트렝스";
    case "setimeta":
      return "세티메타";
  }
};

/**
 * @description 측정 범위 라벨 추출
 * @param detectionRange 측정 범위
 * @returns 측정 범위 라벨
 */
export const getDetectionRangeLabel = (detectionRange: DetectionRangeType) => {
  if (detectionRange.includes("hy")) {
    return "응결/강도";
  }

  switch (detectionRange) {
    case "setting":
      return "응결";
    case "strength":
      return "강도";
    case "hybrid":
      return "응결/강도";
  }
};

/**
 * @description 두 수가 거의 같은지 확인
 * @param a 수1
 * @param b 수2
 * @param epsilon 오차 범위
 * @returns 두 수가 거의 같은지 여부
 */
export const floatsAreEqual = (a: number, b: number, epsilon = 1e-10) => {
  return Math.abs(a - b) < epsilon;
};

/**
 * @description 이미지 로드
 * @param imgFileId 이미지 파일 ID
 * @returns 이미지 URL
 */
export const loadImage = async (imgFileId: string) => {
  const result = await getFile(imgFileId);

  try {
    const url = URL.createObjectURL(result);

    return url;
  } catch (e) {
    return null;
  }
};

/**
 * @description 이미지 캡처
 * @param title 이미지 제목
 * @param ref 이미지 컨테이너
 * @returns 이미지 FormData
 */
export const handleCapture = async (
  title: string,
  ref: React.RefObject<HTMLDivElement>
): Promise<FormData | undefined> => {
  if (!ref.current) return;

  try {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isMobile = window.innerWidth <= 400;
    const scaleValue = isMobile ? 5 : 2;

    let dataUrl: any = null;
    if (isSafari) {
      const canvas = await html2canvas(ref.current, {
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
        scale: scaleValue, // 고해상도 캡처
        useCORS: true, // CORS 문제 방지
        backgroundColor: "#ffffff", // 투명 배경 방지
      });

      dataUrl = canvas.toDataURL("image/png"); // PNG로 변환
    } else {
      dataUrl = await domtoimage.toPng(ref.current, {
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
        style: {
          transform: "scale(0.8)",
          "transform-origin": "top left",
        },
        quality: 1,
        scale: scaleValue,
      });
    }

    // dataURL을 Blob으로 변환
    const response = await fetch(dataUrl);
    const blob = await response.blob();

    // Blob을 FormData로 변환
    const formData = new FormData();
    formData.append("image", blob, `${title}.png`);

    return formData;
  } catch (error) {
    console.error("이미지 생성 중 오류가 발생했습니다.", error);
  }
};

/**
 * @description 경과 시간 포맷
 * @param milliseconds 밀리초
 * @returns 경과 시간
 */
export const formatElapsedTime = (milliseconds: number): string => {
  const hours = milliseconds / (1000 * 60 * 60); // 밀리초를 시간으로 변환
  return `${Math.floor(hours * 100) / 100}`; // 소수점 둘째 자리까지 버림
};

import { create } from "zustand";

type BluetoothStoreType = {
  device: any;
  characteristic: any;
  setDevice: (device: any) => void;
  setCharacteristic: (characteristic: any) => void;
};

const useBluetoothStore = create<BluetoothStoreType>((set) => ({
  device: null,
  characteristic: null,
  setDevice: (device: any) => set({ device: device }),
  setCharacteristic: (characteristic: any) =>
    set({ characteristic: characteristic }),
}));

export default useBluetoothStore;

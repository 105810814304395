import styled from "styled-components";

export const StyledMainPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 36px 24px;

  .search-wrap {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
    align-items: center;
  }

  .bread-crumb-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .bread-crumb-btn-wrap {
      display: flex;
      gap: 4px;
      align-items: center;
    }
    .select-mode-btn-wrap {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .main-list-paper {
    width: 100%;
    height: calc(100% - 96px);
    .main-list-wrap {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  .add-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
`;

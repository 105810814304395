import styled from "styled-components";

export const StyledCreateFolderWrap = styled.div`
  width: 100%;
  .folder-form-wrap-item {
    &:first-child {
      margin-top: 16px;
    }
    width: 100%;
    height: 68px;
    flex-shrink: 0;
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
`;

import { Paper } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StyledImagePreview } from "./StyledImagePreview";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import "pdfjs-dist/web/pdf_viewer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Document, Page } from "react-pdf";

import { pdfjs } from "react-pdf";
import CustomFloatingButton from "../input/button/CustumFloatingButton/CustomFloatingButton";
import CustomPopup from "../popup/CustomPopup";
import Marker from "./marker/Marker";
import {
  coordinateType,
  DeviceType,
  MarkerDataType,
} from "../../types/common/commonTypes";
import dayjs from "dayjs";
import MeasurePopup from "./measurePopup/MeasurePopup";
import { convertFormulas } from "../../utils/ConversionFormula";
import { createMark, getProjectDetail } from "../../api/projectApi";
import DetailPopup from "./detailPopup/DetailPopup";
import useProjectStore from "../../stores/useProjectStore";
import useLoadingStore from "../../stores/useLoadingStore";
import { useParams } from "react-router-dom";
import {
  floatsAreEqual,
  formatElapsedTime,
  getDetectionRangeLabel,
  getDeviceTypeLabel,
  handleCapture,
} from "../../utils/commonUtils";
import { downloadFile } from "../../api/fileApi";
import useAlertStore from "../../stores/useAlertStore";
import { MoonLoader } from "react-spinners";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CustomButton from "../input/button/CustomButton";
import {
  DATE_FORMAT,
  FORMULA_KEYS,
  SERVER_DATA_FORMAT,
} from "../../const/measureConst";

// PDF.js 워커 파일 설정
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;
type ImagePreviewProps = {
  file: File | null;
  coordinateList: coordinateType[];
  previewUrl?: string;
  isMarkerAble?: boolean;
  isDetail?: boolean;
  handleClick: () => void;
};

const ImagePreview = ({
  file,
  coordinateList,
  previewUrl,
  isMarkerAble = true,
  isDetail,
  handleClick,
}: ImagePreviewProps) => {
  const { projectId } = useParams();
  const { activeAlert } = useAlertStore();
  const { setIsLoading } = useLoadingStore();
  const { projectDetail, setProjectDetail } = useProjectStore();
  const containerRef = useRef<HTMLDivElement>(null); // 컨테이너 참조
  const layerRef = useRef<HTMLDivElement>(null);
  const detailPopupRef = useRef<any>(null);

  const [markList, setMarkList] = useState<MarkerDataType[]>([]);
  const [deviceType, setDeviceType] = useState<DeviceType>("hybrid");

  const [isDownloading, setIsDownloading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedCoordinate, setSelectedCoordinate] = useState<{
    xRatio: number;
    yRatio: number;
    coordinateId: number;
  } | null>(null);
  const [currentMarkerData, setCurrentMarkerData] =
    useState<MarkerDataType | null>(null); // 현재 측정 중인 데이터 (Edit / View를 구분하는 기준이 됨)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMarkerMode, setIsMarkerMode] = useState(false);
  const [containerSize, setContainerSize] = useState({
    width: 1,
    height: 1,
  });
  const [imageSize, setImageSize] = useState({
    width: 1,
    height: 1,
  });
  const [scale, setScale] = useState(1);
  const [fileType, setFileType] = useState<"img" | "pdf" | "">("");
  const [previewSrc, setPreviewSrc] = useState("");

  const imageRef = useRef<HTMLDivElement>(null);

  /**
   * @description 이미지 크기 계산 (layer 영역의 너비를 잡아 마커를 정확한 위치에 그려주도록)
   * @param url 이미지 URL
   */
  const calcImgSize = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
  };

  /**
   * @description 파일 변경 시 화면에 이미지 프리뷰를 보여줌
   */
  const handleFileChange = async () => {
    // 상세 화면으로 진입하여 previewUrl이 있을 경우
    if (previewUrl) {
      setPreviewSrc(previewUrl);
      setFileType("img");
      calcImgSize(previewUrl);
      return;
    }

    if (!file) {
      setPreviewSrc("");
      setFileType("");
      return;
    }

    // 새로 업로드한 파일인 경우
    const fileType = file.type;

    if (fileType.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          calcImgSize(e.target.result as string);
          setPreviewSrc(e.target.result as string);
        }
      };

      reader.readAsDataURL(file); // 파일을 DataURL로 읽음
      setFileType("img");
    } else if (fileType === "application/pdf") {
      setPreviewSrc("");
      setFileType("pdf");
    }
  };

  /**
   * @description PDF 페이지 로드 성공 시 페이지 크기 설정
   * @param page PDF 페이지
   */
  const onLoadSuccess = (page: any) => {
    // PDF 페이지의 원본 크기 설정
    const { width, height } = page.getViewport({ scale: 1 });
    setImageSize({ width, height });
  };

  /**
   * @description 컨테이너 크기에 맞춰 이미지 크기 비율을 계산하여 설정
   */
  const calculateScale = () => {
    const { width: containerWidth, height: containerHeight } = containerSize;
    const { width: pdfWidth, height: pdfHeight } = imageSize;

    const widthScale = containerWidth / pdfWidth;
    const heightScale = containerHeight / pdfHeight;

    setScale(Math.min(widthScale, heightScale));
  };

  /**
   * @description 이미지 클릭 시 마커 추가
   * @param event 이벤트
   */
  const handleImageClick = (event: React.MouseEvent) => {
    if (!isMarkerMode) return;

    event.stopPropagation();
    const layer = layerRef.current;
    const layerRect = layer?.getBoundingClientRect();

    if (!layerRect) return;

    // 클릭한 위치를 이미지의 비율로 계산
    const xRatio = (event.clientX - layerRect.left) / layerRect.width;
    const yRatio = (event.clientY - layerRect.top) / layerRect.height;

    setSelectedCoordinate({ xRatio, yRatio, coordinateId: -1 });

    setIsMarkerMode(false);
    setIsPopupOpen(true);
  };

  /**
   * @description 기기 혹은 사용자에게 HD 값을 입력받을 시 측정 정보 기록 팝업 open
   * @param data 측정 결과
   */
  const onInfoChange = useCallback(
    (data: Partial<MarkerDataType>) => {
      if (selectedCoordinate) {
        const newObj = {
          pouringTime: dayjs(new Date()).format(SERVER_DATA_FORMAT),
          nowTime: "",
          deviceType: "hybrid",
          detectionRange: "strength",
          concreteTemperature: 0,
          concreteHd: 0,
          convertedMpa: 0,
        };

        const {
          concreteHd = 0,
          deviceType = "hybrid",
          detectionRange = "strength",
          pouringTime = newObj.pouringTime,
          thickness = "15",
        } = data;

        let hybridType = "";
        let hybridDetectionRange: any = "";

        // 하이브리드라면 종결을 넘어간 시점에서는 강도로 측정되어야 함
        if (deviceType === "hybrid" && detectionRange === "hybrid") {
          const result = convertFormulas(
            concreteHd,
            `${deviceType}${thickness}setting` as any
          );
          if (result > 28) {
            hybridType = "strength";
            hybridDetectionRange = `hy-${thickness}-st`;
          } else {
            hybridType = "setting";
            hybridDetectionRange = `hy-${thickness}-se`;
          }
        }

        const result = convertFormulas(
          concreteHd,
          deviceType === "hybrid"
            ? `${deviceType}${thickness}${
                hybridType ? hybridType : detectionRange
              }`
            : deviceType
        );

        setCurrentMarkerData({
          ...newObj,
          convertedMpa: result,
          nowTime: dayjs(new Date()).format(SERVER_DATA_FORMAT),
          pouringTime,
          concreteHd,
          deviceType,
          detectionRange:
            detectionRange === "hybrid" ? hybridDetectionRange : detectionRange,
        });

        setIsDetailOpen(true);
        setIsPopupOpen(false);
      }
    },
    [selectedCoordinate, currentMarkerData]
  );

  /**
   * @description 마커 클릭시 클릭된 마커를 저장하고 메뉴 시트 open
   * @param e 이벤트
   * @param marker 마커
   */
  const handleMenuOpen = async (
    e: React.MouseEvent<HTMLButtonElement>,
    marker: coordinateType
  ) => {
    e.stopPropagation();
    if (isMarkerMode) return;

    setAnchorEl(e.currentTarget);

    setSelectedCoordinate({
      xRatio: marker.xRatio,
      yRatio: marker.yRatio,
      coordinateId: marker.coordinateId,
    });
  };

  /**
   * @description 프로젝트 상세 정보 조회
   */
  const fetchProjectDetail = async () => {
    if (!projectId) return;
    const result = await getProjectDetail(projectId);

    if (result.isSuccess && result.resultList) {
      setProjectDetail({ ...projectDetail, ...result.resultList });
    }
  };

  /**
   * @description 측정 정보까지의 모든 값 입력 후 마커 저장
   * @param markerData 마커 데이터
   */
  const onSaveMarker = async (markerData: MarkerDataType) => {
    if (projectId) {
      setIsLoading(true);
      const result = await createMark({
        projectId: projectId,
        ...markerData,
        xRatio: selectedCoordinate?.xRatio ?? 0,
        yRatio: selectedCoordinate?.yRatio ?? 0,
      } as any);

      if (result.isSuccess) {
        setCurrentMarkerData(null);
        setSelectedCoordinate(null);
        setAnchorEl(null);
        await fetchProjectDetail();
        setIsDetailOpen(false);
      }

      setIsLoading(false);
    }
  };

  /**
   * @description 메뉴 시트 닫기
   */
  const handleMenuClose = () => {
    setSelectedCoordinate(null);
    setAnchorEl(null);
  };

  /**
   * @description 마커 클릭 시 마커 상세 정보 팝업 open
   * @param marker 마커
   */
  const onViewMarker = (marker: coordinateType) => {
    setAnchorEl(null);

    setSelectedCoordinate({
      xRatio: marker.xRatio,
      yRatio: marker.yRatio,
      coordinateId: marker.coordinateId,
    });

    setIsDetailOpen(true);
  };

  /**
   * @description 측정 데이터 excel 다운로드
   */
  const onDownload = async () => {
    setIsDownloading(true);

    const formData = await handleCapture("image", layerRef);

    if (!formData) {
      setIsDownloading(false);
      return;
    }

    const coordinateIndex =
      projectDetail?.coordinateList.findIndex(
        (coordinate) =>
          selectedCoordinate?.coordinateId === coordinate.coordinateId
      ) ?? -1;

    // 초결, 종결 시간과 5MPa 도달시간
    const { checkingTime } = detailPopupRef?.current;

    let dataList: any = [];

    if (deviceType === "hybrid") {
      dataList = markList.map((mark: MarkerDataType) => {
        if (mark.detectionRange === "strength") {
          return { ...mark, type: "strength" };
        } else if (mark.detectionRange === "setting") {
          return { ...mark, type: "setting" };
        } else if (mark.detectionRange.includes("hy")) {
          const [_, thickness, detectionRange] = mark.detectionRange.split("-");

          if (detectionRange === "st") {
            return { ...mark, type: "strength" };
          } else if (detectionRange === "se") {
            return { ...mark, type: "setting" };
          }
        }
      });
    } else {
      dataList = markList.map((mark: any) => ({
        ...mark,
        type: mark.detectionRange,
      }));
    }

    /**
     * 초결 경과시간 ⇒ initialSettingTime
     * 종결 경과시간 ⇒ finalSettingTime
     * 초결 시간 ⇒ initialSettingTime
     * 종결 시간 ⇒ finalSettingTime
     * 5MPa 도달 시간 ⇒ fiveMpaTime
     * 경과 시간 ⇒ duration
     * 응결 MPa ⇒ convertedMpaSetting
     * 강도 MPa ⇒ convertedMpaStrength
     */
    const projectData = {
      dataInfo: {
        placeName: projectDetail?.projectTitle,
        pouringTime: dayjs(markList[0].pouringTime).format(DATE_FORMAT),
        deviceType: getDeviceTypeLabel(deviceType),
        createdUsername: projectDetail?.createdName,
        initialSettingDuration: checkingTime.initialSettingTime ?? "-",
        finalSettingDuration: checkingTime.finalSettingTime ?? "-",
        fiveMpaTime: checkingTime.fiveMPaTime
          ? dayjs(markList[0].pouringTime)
              .add(checkingTime.fiveMPaTime, "hour")
              .format(DATE_FORMAT)
          : "-",
        initialSettingTime: checkingTime.initialSettingTime
          ? dayjs(markList[0].pouringTime)
              .add(checkingTime.initialSettingTime, "hour")
              .format(DATE_FORMAT)
          : "-",
        finalSettingTime: checkingTime.finalSettingTime
          ? dayjs(markList[0].pouringTime)
              .add(checkingTime.finalSettingTime, "hour")
              .format(DATE_FORMAT)
          : "-",
        detectLocation: `${coordinateIndex + 1}번 마커`,
      },
      dataList: dataList.map((mark: any) => ({
        nowTime: dayjs(mark.nowTime).format(DATE_FORMAT),
        concreteTemperature: mark.concreteTemperature,
        concreteHd: mark.concreteHd,
        detectionRange: getDetectionRangeLabel(mark.detectionRange),
        duration: formatElapsedTime(
          dayjs(mark.nowTime).diff(dayjs(mark.pouringTime))
        ),
        convertedMpaSetting: mark.type === "setting" ? mark.convertedMpa : "",
        convertedMpaStrength: mark.type === "strength" ? mark.convertedMpa : "", // type에 따라 MPa 셀 구분
      })),
    };

    const contentblob = new Blob([JSON.stringify(projectData)], {
      type: "application/json",
    });

    formData.append("data", contentblob);

    const result = await downloadFile(formData);

    if (!result) return;

    try {
      // Blob 생성
      const blob = new Blob([result], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Blob URL 생성
      const blobUrl = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${getFileName()}.xlsx`; // 다운로드 파일 이름
      document.body.appendChild(link);
      link.click();

      // 링크 제거 및 Blob URL 해제
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);

      activeAlert({
        type: "success",
        message: "데이터를 다운로드하였습니다.",
        timeout: 3000,
      });
    } catch (e) {
      activeAlert({
        type: "error",
        message: "데이터를 다운로드하는 중 오류가 발생했습니다.",
        timeout: 3000,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  /**
   * @description 파일 이름 생성
   * @returns 파일 이름
   */
  const getFileName = () => {
    const coordinateIndex =
      projectDetail?.coordinateList.findIndex(
        (coordinate) =>
          selectedCoordinate?.coordinateId === coordinate.coordinateId
      ) ?? -1;

    return `${projectDetail?.projectTitle}_${deviceType}_${(coordinateIndex + 1)
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current;
        setContainerSize({ width: offsetWidth, height: offsetHeight });
      }
    };

    updateContainerSize();
    window.addEventListener("resize", updateContainerSize);

    return () => {
      window.removeEventListener("resize", updateContainerSize);
    };
  }, []);

  useEffect(() => {
    handleFileChange();
  }, [previewUrl]);

  // 파일 변경 시 프리뷰 이미지 업데이트
  useEffect(() => {
    handleFileChange();
  }, [file]);

  useEffect(() => {
    calculateScale();
  }, [containerSize, imageSize]);

  /**
   * 전체적인 흐름: coordinate가 좌표, mark가 좌표 내에 있는 여러 개의 측정값
   * 1. MeasurePopup에서 측정 정보 입력후 MeasureHD 다이얼로그 open
   * 2. 다이얼로그에서 HD 값을 측정하거나 직접 입력
   * 3. 측정 정보를 토대로 MPa 값 계산
   * 4. marker 저장
   * 5. 이후 측정한 정보를 토대로 초결 시간, 종결 시간, 5MPa 도달 시간을 계산
   */
  // 전체적인 흐름
  return (
    <>
      <StyledImagePreview
        ref={containerRef}
        onClick={() => {
          if (selectedCoordinate === null) handleClick();
        }}
      >
        <Paper
          variant="elevation"
          square={false}
          elevation={3}
          className="wh-100"
        >
          {fileType === "img" ? (
            <div className="image-box"></div>
          ) : fileType === "pdf" ? (
            <div className="pdf-wrap" ref={imageRef}>
              <Document file={file}>
                <Page
                  pageNumber={1}
                  scale={scale}
                  onLoadSuccess={onLoadSuccess}
                />
              </Document>
            </div>
          ) : (
            <p className="placeholder-text">
              <PhotoOutlinedIcon fontSize="large" />
            </p>
          )}

          <div
            ref={layerRef}
            className="layer"
            onClick={handleImageClick}
            style={{
              backgroundImage: `url("${previewSrc}")`,
              width: `${imageSize.width * scale}px`,
              height: `${imageSize.height * scale}px`,
            }}
          >
            {coordinateList.map((marker, index: number) => {
              const x = marker.xRatio * imageSize.width * scale;
              const y = marker.yRatio * imageSize.height * scale;

              return (
                <Marker
                  key={`${x}-${y}`}
                  x={x}
                  y={y}
                  index={index}
                  isDetail={isDetail}
                  // marker={marker}
                  isSelected={
                    selectedCoordinate?.xRatio === marker.xRatio &&
                    selectedCoordinate?.yRatio === marker.yRatio
                  }
                  anchorEl={anchorEl}
                  togglePopup={(isOpen) => {
                    setIsPopupOpen(isOpen);
                    setAnchorEl(null);
                  }}
                  handleMenuOpen={(e) => handleMenuOpen(e, marker)}
                  onViewMarker={() => onViewMarker(marker)}
                  handleMenuClose={handleMenuClose}
                />
              );
            })}
          </div>

          {(file || previewUrl) && isMarkerAble && (
            <CustomFloatingButton
              tooltip={{ label: "Add Point", direction: "top" }}
              className="image-edit-btn"
              size="small"
              color="primary"
              aria-label="edit"
              icon={isMarkerMode ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setIsMarkerMode(!isMarkerMode);
              }}
            />
          )}
        </Paper>
      </StyledImagePreview>
      <CustomPopup
        isOpen={isPopupOpen}
        children={
          <MeasurePopup
            onInfoChange={onInfoChange}
            onCancel={() => {
              setIsPopupOpen(false);
              setSelectedCoordinate(null);
              setAnchorEl(null);
            }}
          />
        }
        title="측정 결과 기록"
        setIsOpen={setIsPopupOpen}
        onClose={() => {
          setIsPopupOpen(false);
          setSelectedCoordinate(null);
          setAnchorEl(null);
        }}
      />
      <CustomPopup
        title="측정 이력"
        setIsOpen={setIsDetailOpen}
        isOpen={isDetailOpen}
        onClose={() => {
          setIsDetailOpen(false);
          setSelectedCoordinate(null);
          setCurrentMarkerData(null);
          setAnchorEl(null);
          setMarkList([]);
          setDeviceType("hybrid");
        }}
        rightChildren={
          markList.length > 0 && !currentMarkerData ? (
            <div style={{ background: "#FFFFFF", borderRadius: "4px" }}>
              <CustomButton
                label="다운로드"
                type="outlined"
                color="primary"
                size="small"
                onClick={onDownload}
                disabled={isDownloading}
                startIcon={
                  isDownloading ? (
                    <MoonLoader size={15} />
                  ) : (
                    <CloudDownloadIcon />
                  )
                }
              />
            </div>
          ) : undefined
        }
        children={
          <DetailPopup
            ref={detailPopupRef}
            selectedCoordinate={selectedCoordinate?.coordinateId ?? -1}
            currentMarker={currentMarkerData}
            deviceType={deviceType}
            setDeviceType={setDeviceType}
            markList={markList}
            setMarkList={setMarkList}
            onCancel={() => {
              setIsDetailOpen(false);
              setAnchorEl(null);
              setCurrentMarkerData(null);
              setSelectedCoordinate(null);
              setMarkList([]);
              setDeviceType("hybrid");
            }}
            onSave={onSaveMarker}
          />
        }
      />
    </>
  );
};

export default ImagePreview;

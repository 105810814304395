import { IconButton } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import MoreMenu from "../../moreMenu/MoreMenu";
import { StyledMarker } from "./StyledMarker";

type MarkerProps = {
  x: number;
  y: number;
  index: number;
  isSelected: boolean;
  anchorEl: HTMLElement | null;
  isDetail?: boolean;
  togglePopup: (isOpen: boolean) => void;
  handleMenuOpen: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleMenuClose: () => void;
  onViewMarker: () => void;
};

const Marker = ({
  x,
  y,
  index,
  isSelected,
  anchorEl,
  isDetail,
  togglePopup,
  handleMenuOpen,
  handleMenuClose,
  onViewMarker,
}: MarkerProps) => {
  return (
    <>
      <IconButton
        className="marker-icon-btn"
        sx={{
          left: `${x}px`,
          top: `${y}px`,
          padding: 0,
          position: "absolute",
        }}
        edge="end"
        aria-label="more"
        onClick={(e) => handleMenuOpen(e)}
        aria-controls={isSelected && anchorEl ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isSelected && anchorEl ? "true" : undefined}
      >
        <StyledMarker
          style={{
            backgroundColor: isSelected ? "#f4a261" : "#497DB8",
          }}
        >
          <div className="marker-number">
            <span>{String(index + 1).padStart(2, "0")}</span>
          </div>
        </StyledMarker>
      </IconButton>
      <MoreMenu
        id="basic-menu"
        direction="left"
        anchorEl={anchorEl}
        open={anchorEl !== null && isSelected}
        handleClose={handleMenuClose}
        buttonList={[
          {
            label: "추가",
            icon: <AddIcon />,
            hidden: isDetail,
            onClick: () => togglePopup(true),
          },
          {
            label: "조회",
            icon: <SearchIcon />,
            onClick: () => onViewMarker(),
          },
        ]}
      />
    </>
  );
};

export default Marker;

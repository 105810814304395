import React, { useState } from "react";
import { AppBar, Avatar, Toolbar, Typography } from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import useAuthStore from "../../../stores/useAuthStore";
import CustomButton from "../../input/button/CustomButton";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import useDialogStore from "../../../stores/useDialogStore";
import useAuth from "../../../hooks/useAuth";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";
import MoreMenu from "../../moreMenu/MoreMenu";
import { StyledUserInfo } from "./StyledHeader";
import useProjectStore from "../../../stores/useProjectStore";

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { openDialog, closeDialog } = useDialogStore();
  const { isLogin, userInfo } = useAuthStore();
  const { setSelectedFolderData } = useProjectStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogoutButton = () => {
    openDialog({
      size: "small",
      title: "Logout",
      state: "info",
      content: "로그아웃 하시겠습니까?",
      button: {
        label: "확인",
        onClick: () => {
          logout();
          closeDialog();
        },
      },
      secondButton: {
        label: "취소",
        onClick: closeDialog,
      },
    });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {pathname !== "/login" && pathname !== "/" && (
          <CustomButton
            type="text"
            color="inherit"
            size="small"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => navigate(-1)}
          />
        )}
        <PinDropIcon fontSize="large" sx={{ marginRight: "0.8rem" }} />
        <Typography
          variant="h5"
          component="div"
          sx={{ flexGrow: 1, fontWeight: 600, cursor: "pointer" }}
          onClick={() => {
            if (pathname !== "/") setSelectedFolderData(null);
            navigate("/");
          }}
        >
          추정계
        </Typography>

        {isLogin && pathname !== "/signup" && (
          <StyledUserInfo>
            <p>{userInfo.loginId}</p>
            <Avatar
              sx={{ background: "#FFFFFF", width: "36px", height: "36px" }}
              onClick={handleClick}
            >
              <PersonIcon color="action" />
            </Avatar>
            <MoreMenu
              id="basic-menu"
              direction="bottom"
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              buttonList={[
                {
                  label: "로그아웃",
                  icon: <LogoutIcon />,
                  autoClose: true,
                  onClick: onClickLogoutButton,
                },
              ]}
            />
          </StyledUserInfo>
          // <CustomButton
          //   label="Logout"
          //   type="text"
          //   color="inherit"
          //   size="small"
          //   startIcon={<LogoutIcon />}
          //   onClick={onClickLogoutButton}
          // />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

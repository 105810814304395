import styled from "styled-components";

export const StyledDetailPopup = styled.div`
  width: 100%;
  .detail-title-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .type-wrap {
      display: flex;
      gap: 8px;
    }
  }
  .converted-MPa {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-list-wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .empty-list-notice {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .table-wrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .row-content-box {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .row-content-wrapper {
        display: flex;
        gap: 8px;
        width: 100%;
        .content-textfield {
          width: calc(100% - 100px);
          display: flex;
          gap: 4px;
        }
        .detail-title-btn-wrap {
          display: flex;
          gap: 4px;
        }
      }
    }
  }
`;

import React, { useEffect, useMemo, useState } from "react";
import { StyledMainPage } from "./StyledMainPage";
import CustomFloatingButton from "../../components/input/button/CustumFloatingButton/CustomFloatingButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import CommonList from "../../components/list/CustomList/CustomList";
import useProjectStore from "../../stores/useProjectStore";
import { CustomTextField } from "../../components/input/textField/CustomTextField";
import { getFolderList, getProjectList } from "../../api/projectApi";
import { debounce } from "lodash";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import useDialogStore from "../../stores/useDialogStore";
import CreateFolderDialog from "./component/folder/create/CreateFolderDialog";
import { FolderItemType } from "../../types/common/projectTypes";
import CustomButton from "../../components/input/button/CustomButton";
import MoveFolderDialog from "./component/folder/move/MoveFolderDialog";

const MainPage = () => {
  const {
    selectedFolderData,
    setProjectList,
    setProjectDetail,
    setSelectedFolderData,
  } = useProjectStore();
  const { openDialog } = useDialogStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedProjectList, setSelectedProjectList] = useState<string[]>([]);

  const handleSearchValue = (searchText: string) => {
    setSearchValue(searchText);

    debounceSetListData.cancel();

    debounceSetListData(searchText);
  };

  const debounceSetListData = useMemo(
    () =>
      debounce((searchText: string) => {
        if (selectedFolderData) {
          loadProjectListByFolder(selectedFolderData, searchText);
        } else {
          loadProjectList(searchText);
        }
      }, 1000),
    [selectedFolderData]
  );

  const loadProjectList = async (searchText?: string, allProject?: boolean) => {
    setLoading(true);
    const result = await getProjectList(
      allProject ? "" : searchText ?? searchValue,
      allProject ? "" : selectedFolderData?.finderId
    );

    const folderResult = await getFolderList();

    if (result.isSuccess && folderResult.isSuccess) {
      setProjectList([...folderResult.resultList, ...result.resultList]);
    }
    setLoading(false);
  };

  const loadProjectListByFolder = async (
    folder: FolderItemType,
    searchText?: string
  ) => {
    setLoading(true);
    if (!searchText) setSearchValue("");
    const result = await getProjectList(searchText ?? "", folder.finderId);
    setProjectList(result.resultList);
    setSelectedFolderData(folder);
    setLoading(false);
  };

  const onSaveFolder = () => {
    setIsSelectMode(false);
    setSelectedProjectList([]);
  };

  const openCreateFolderDialog = (folder?: FolderItemType) => {
    openDialog({
      size: "small",
      state: "info",
      title: "폴더 생성",
      content: <CreateFolderDialog folder={folder} />,
    });
  };

  const handleSelectProject = (projectId: string) => {
    if (selectedProjectList.includes(projectId)) {
      setSelectedProjectList(
        selectedProjectList.filter((p) => p !== projectId)
      );
    } else {
      setSelectedProjectList([...selectedProjectList, projectId]);
    }
  };

  useEffect(() => {
    return () => {
      setIsSelectMode(false);
      setSelectedProjectList([]);
      // resetProjectStore();
    };
  }, []);

  return (
    <StyledMainPage>
      <div className="search-wrap">
        <CustomTextField
          id="search"
          icon={<SearchIcon />}
          value={searchValue}
          placeholder="제목 검색"
          handleChange={handleSearchValue}
        />
      </div>
      <div className="bread-crumb-wrap">
        <div className="bread-crumb-btn-wrap">
          <CustomButton
            type="text"
            color="primary"
            size="small"
            label="전체"
            onClick={async () => {
              if (selectedFolderData) {
                setIsSelectMode(false);
                setSelectedProjectList([]);
                setSearchValue("");
                await setSelectedFolderData(null);
                await loadProjectList(undefined, true);
              }
            }}
          />
          {selectedFolderData && (
            <>
              <span>&gt;</span>
              <CustomButton
                type="text"
                color="primary"
                size="small"
                label={
                  selectedFolderData.finderName.length > 10
                    ? `${selectedFolderData.finderName.slice(0, 10)}...`
                    : selectedFolderData.finderName
                }
              />
            </>
          )}
        </div>
        {isSelectMode ? (
          <div className="select-mode-btn-wrap">
            <CustomButton
              type="outlined"
              color="primary"
              size="small"
              label="취소"
              onClick={() => {
                setIsSelectMode(false);
                setSelectedProjectList([]);
              }}
            />
            <CustomButton
              type="contained"
              color="primary"
              size="small"
              label={`${selectedProjectList.length}개 이동`}
              disabled={selectedProjectList.length === 0}
              onClick={() => {
                openDialog({
                  size: "small",
                  state: "info",
                  title: "폴더 이동",
                  content: (
                    <MoveFolderDialog
                      selectedProjectList={selectedProjectList}
                      onSave={onSaveFolder}
                    />
                  ),
                });
              }}
            />
          </div>
        ) : (
          <CustomButton
            type="contained"
            color="primary"
            size="small"
            label="폴더 이동"
            onClick={() => setIsSelectMode(true)}
          />
        )}
      </div>
      <Paper
        variant="elevation"
        square={false}
        elevation={3}
        className="main-list-paper"
      >
        <div className="main-list-wrap">
          <CommonList
            isSelectedMode={isSelectMode}
            loading={loading}
            selectedProjectList={selectedProjectList}
            loadProjectList={loadProjectList}
            loadProjectListByFolder={loadProjectListByFolder}
            openCreateFolderDialog={openCreateFolderDialog}
            handleSelectProject={handleSelectProject}
          />
        </div>
      </Paper>
      {!isSelectMode && (
        <CustomFloatingButton
          className="add-btn"
          icon={<AddOutlinedIcon />}
          size="large"
          color="primary"
          actions={[
            {
              name: "createProject",
              tooltipTitle: "프로젝트생성",
              icon: <NoteAddIcon />,
              onClick: () => {
                setProjectDetail(null);
                navigate("/edit");
              },
            },
            {
              name: "createFolder",
              tooltipTitle: "폴더생성",
              hidden: !!selectedFolderData,
              icon: <CreateNewFolderIcon />,
              onClick: () => openCreateFolderDialog(),
            },
          ]}
        />
      )}
    </StyledMainPage>
  );
};

export default MainPage;

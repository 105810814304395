import { checkErrorType } from "../utils/apiUtils";
import axiosInstance from "./axiosInstance";

export const uploadFileWithSave = async (formData: any) => {
  try {
    const response = await axiosInstance.post("/file/upload", formData);
    if (response.data.isSuccess) {
      return { ...response.data, isSuccess: true };
    } else if (response.data.code === 401) {
      checkErrorType("", 401);
      return { isSuccess: false };
    }
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

export const getFile: any = async (fileId: string) => {
  try {
    const response = await axiosInstance.get(`/file/${fileId}`, {
      responseType: "blob", // 바이너리 데이터를 요청
      headers: {
        Accept: "image/*", // 이미지 형식 요청
      },
    });
    return response.data;
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

export const downloadFile = async (formData: any) => {
  try {
    const response = await axiosInstance.post("/excels", formData, {
      responseType: "blob",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // 이미지 형식 요청
      },
    });
    return response.data;
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};

import styled from "styled-components";

export const StyledLoading = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;

  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    gap: 8px;
    border-radius: 8px;
    padding: 24px 36px;
  }
`;

import styled from "styled-components";

export const StyledAddGroupDialog = styled.div`
  width: 100%;
  height: 100%;

  .password-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .password-input-wrap {
    height: 68px;
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;

import styled from "styled-components";

export const StyledFileUpload = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
  .upload-button {
    margin-top: 2px;
  }
`;

import { create } from "zustand";
import { FolderItemType, ProjectItemType } from "../types/common/projectTypes";

export interface ProjectStoreType {
  projectList: ProjectItemType[] | FolderItemType[];
  projectDetail: ProjectItemType | null;
  projectReloadTrigger: number;
  selectedFolderData: FolderItemType | null;
  setProjectList: (projectList: any[]) => void;
  setProjectDetail: (detail: ProjectItemType | null) => void;
  setProjectReloadTrigger: () => void;
  setSelectedFolderData: (data: FolderItemType | null) => void;
  resetProjectStore: () => void;
}

const useProjectStore = create<ProjectStoreType>((set) => ({
  projectList: [],
  projectDetail: null,
  projectReloadTrigger: 0,
  selectedFolderData: null,
  setProjectList: (projectList: any[]) => set({ projectList: projectList }),
  setProjectDetail: (detail: ProjectItemType | null) =>
    set({ projectDetail: detail }),
  setProjectReloadTrigger: () =>
    set((state) => ({ projectReloadTrigger: state.projectReloadTrigger + 1 })),
  setSelectedFolderData: (data: FolderItemType | null) =>
    set({ selectedFolderData: data }),
  resetProjectStore: () =>
    set({
      projectList: [],
      projectDetail: null,
      projectReloadTrigger: 0,
    }),
}));

export default useProjectStore;

import React, { useState } from "react";
import { StyledLoginPage } from "./StyledLoginPage";
import { CustomTextField } from "../../components/input/textField/CustomTextField";
import CustomButton from "../../components/input/button/CustomButton";
import useAuth from "../../hooks/useAuth";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [errorInfo, setErrorInfo] = useState({
    isError: false,
    errorType: "",
    errorMessage: "",
  });
  const [loginId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const onLoginButtonClick = async () => {
    if (loginId === "" || password === "") {
      setErrorInfo({
        isError: true,
        errorType: loginId === "" ? "userId" : "password",
        errorMessage:
          loginId === ""
            ? "아이디를 입력해 주세요."
            : "패스워드를 입력해 주세요",
      });
      return;
    }

    await debouncedLogin();
  };

  const debouncedLogin = debounce(
    async () => {
      await login({ loginId, password });
    },
    500,
    { leading: true, trailing: false }
  );
  const resetErrorInfo = () => {
    setErrorInfo({
      isError: false,
      errorType: "",
      errorMessage: "",
    });
  };

  return (
    <StyledLoginPage>
      <div className="login-form-wrap">
        <div className="text-field-wrap">
          <CustomTextField
            id="userId"
            required
            placeholder="ID"
            value={loginId}
            error={errorInfo.errorType === "userId" ? errorInfo : undefined}
            handleChange={(value) => {
              setUserId(value);
              resetErrorInfo();
            }}
            onKeyDown={(e) => {
              if (e?.key === "Enter") debouncedLogin();
            }}
          />
        </div>
        <div className="text-field-wrap">
          <CustomTextField
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            error={errorInfo.errorType === "password" ? errorInfo : undefined}
            handleChange={(value) => {
              setPassword(value);
              resetErrorInfo();
            }}
            onKeyDown={(e) => {
              if (e?.key === "Enter") debouncedLogin();
            }}
          />
        </div>
        <CustomButton
          label="로그인"
          type="contained"
          color="primary"
          size="large"
          onClick={onLoginButtonClick}
        />
        <div className="sign-up-btn">
          <CustomButton
            onClick={() => navigate("/signup")}
            label="회원 가입"
            type="text"
            color="primary"
            size="small"
            width="60px"
          />
        </div>
      </div>
    </StyledLoginPage>
  );
};

export default LoginPage;

import { create } from "zustand";

export interface LoadingStoreType {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const useLoadingStore = create<LoadingStoreType>((set) => ({
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
}));

export default useLoadingStore;

import React from "react";
import { StyledLoading } from "./StyledLoading";
import { SyncLoader } from "react-spinners";
import { Typography } from "@mui/material";

const Loading = () => {
  return (
    <StyledLoading>
      <div className="spinner-wrap">
        <SyncLoader color="#8a9ba8" size={20} speedMultiplier={0.7} />
        <Typography variant="h6" sx={{ color: "#FFFFFF" }}>
          Loading...
        </Typography>
      </div>
    </StyledLoading>
  );
};

export default Loading;

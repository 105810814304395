import styled from "styled-components";

export const StyledMarker = styled.div`
  width: 22px;
  height: 22px;
  position: relative;
  background-color: #f4a261;
  border-radius: 100% 100% 100% 0;
  border: 1px solid #000000;
  transform: rotate(-45deg);
  margin-top: -8px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);

  .marker-number {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    /* border: 1px solid #000000; */
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.3px;

    > span {
      font-size: 12px;
      font-weight: bold;
      color: black;
    }
  }
`;

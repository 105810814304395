import React, { useEffect, useRef, useState } from "react";
import { StyledEditPage } from "./StyledEditPage";
import ImagePreview from "../../components/imagePreview/ImagePreview";
import FileUpload from "../../components/input/file/FileUpload";
import { CustomTextField } from "../../components/input/textField/CustomTextField";
import { Divider } from "@mui/material";
import CustomButton from "../../components/input/button/CustomButton";
import useDialogStore from "../../stores/useDialogStore";

import { useNavigate, useParams } from "react-router-dom";
import { MarkerType } from "../../types/common/commonTypes";
import { uploadFileWithSave } from "../../api/fileApi";
import { getFileExtension, loadImage } from "../../utils/commonUtils";
import useAlertStore from "../../stores/useAlertStore";
import useProjectStore from "../../stores/useProjectStore";
import useLoadingStore from "../../stores/useLoadingStore";
import { getProjectDetail, moveFolder } from "../../api/projectApi";
import { ProjectItemType } from "../../types/common/projectTypes";
import useBluetoothStore from "../../stores/useBluetoothStore";

const EditPage = () => {
  const { projectDetail, selectedFolderData, setProjectDetail } =
    useProjectStore();
  const { openDialog, closeDialog } = useDialogStore();
  const { activeAlert } = useAlertStore();
  const { setIsLoading } = useLoadingStore();

  const { projectId } = useParams();
  const navigate = useNavigate();
  const fileRef = useRef<any>(null);
  const [isDetail, setIsDetail] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [errorTarget, setErrorTarget] = useState("");

  const fetchProjectDetail = async () => {
    if (!projectId) {
      navigate("/");
    } else {
      const result = await getProjectDetail(projectId);

      try {
        if (result.isSuccess && result.resultList) {
          const imgUrl = await loadImage(result.resultList.imgFileId);

          if (!imgUrl) {
            throw Error();
          }

          setProjectDetail({ ...result.resultList, imgUrl: imgUrl });
          setDefaultData({ ...result.resultList, imgUrl: imgUrl });
          setIsDetail(true);
        } else {
          throw Error();
        }
      } catch (e) {
        activeAlert({
          type: "error",
          message: "상세 정보를 불러오지 못 했습니다. 다시 시도해 주세요.",
          timeout: 3000,
        });
      }
    }
  };

  const setDefaultData = (detailData?: ProjectItemType) => {
    const detail = detailData ?? projectDetail;
    if (!detail) return;
    setTitle(detail.projectTitle);
    setDescription(detail.projectDescription ?? "");
  };

  const onCancelClick = () => {
    if (projectId) {
      navigate(-1);
      return;
    }
    openDialog({
      size: "small",
      title: "작성 취소",
      state: "info",
      content: "작성을 취소하고 목록으로 돌아가시겠습니까?",
      button: {
        label: "확인",
        onClick: () => {
          closeDialog();
          navigate("/");
        },
      },
      secondButton: {
        label: "취소",
        onClick: () => closeDialog(),
      },
    });
  };

  const onSaveClick = async () => {
    if (projectId) {
      navigate(-1);
      return;
    }

    if (!title) {
      setErrorTarget("title");
      return;
    } else if (!uploadedFile) {
      setErrorTarget("file");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    let json = JSON.stringify({
      projectType: "test_type",
      uploadFileType: getFileExtension(uploadedFile),
      projectDescription: description,
      projectTitle: title,
    });
    const contentblob = new Blob([json], { type: "application/json" });

    formData.append("uploadFile", uploadedFile);
    formData.append("uploadRequest", contentblob);

    const result = await uploadFileWithSave(formData);

    if (result.isSuccess) {
      if (selectedFolderData) {
        await moveFolder(selectedFolderData.finderId, result.resultObject);
      }
      activeAlert({
        type: "success",
        message: "프로젝트가 저장되었습니다.",
        timeout: 3000,
      });

      navigate("/");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (projectId) {
      if (projectDetail) {
        setDefaultData();
        setIsDetail(true);
      } else {
        fetchProjectDetail();
      }
    }

    // return () => {
    //   setDevice(null);
    //   setCharacteristic(null);
    // };
  }, []);

  return (
    <StyledEditPage>
      <div className="edit-content-wrap">
        <CustomTextField
          id="title"
          value={title}
          disabled={isDetail}
          label="제목"
          handleChange={(value) => {
            setTitle(value);
            setErrorTarget("");
          }}
          error={{
            isError: errorTarget === "title",
            errorMessage: "제목을 입력해 주세요.",
          }}
        />
        <div className="file-wrap">
          <ImagePreview
            file={uploadedFile}
            coordinateList={projectDetail?.coordinateList ?? []}
            previewUrl={projectDetail?.imgUrl}
            isMarkerAble={isDetail ? true : false}
            handleClick={() => fileRef.current?.onFileClick()}
          />
          {isDetail && projectDetail ? (
            <CustomTextField
              id="fileName"
              disabled
              value={projectDetail.fileOriginalName}
            />
          ) : (
            <FileUpload
              ref={fileRef}
              uploadedFile={uploadedFile}
              handleChange={(file) => {
                setUploadedFile(file);
                setErrorTarget("");
              }}
              accept="application/pdf"
              placeholder="도면 파일(.pdf)을 선택해 주세요."
              error={{
                isError: errorTarget === "file",
                errorMessage: "도면을 업로드해 주세요.",
              }}
            />
          )}
        </div>
        <CustomTextField
          id="title"
          value={description}
          disabled={isDetail}
          label="설명"
          handleChange={setDescription}
          multiLine
        />
      </div>
      <Divider />
      <div className="edit-button-wrap">
        {!isDetail && (
          <CustomButton
            type="outlined"
            color="primary"
            size="large"
            label="돌아가기"
            onClick={onCancelClick}
          />
        )}
        <CustomButton
          width="100px"
          type="contained"
          color="primary"
          size="large"
          label={isDetail ? "완료" : "저장"}
          onClick={onSaveClick}
        />
      </div>
    </StyledEditPage>
  );
};

export default EditPage;

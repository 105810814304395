import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../../../../components/input/button/CustomButton";
import useDialogStore from "../../../../../stores/useDialogStore";
import useAlertStore from "../../../../../stores/useAlertStore";
import { StyledMoveFolderWrap } from "./StyeldMoveFolderDialog";
import { getFolderList, moveFolder } from "../../../../../api/projectApi";
import { FolderItemType } from "../../../../../types/common/projectTypes";
import { CustomSelect } from "../../../../../components/input/select/CustomSelect";
import FolderIcon from "@mui/icons-material/Folder";
import useProjectStore from "../../../../../stores/useProjectStore";

export type MoveFolderDialogProps = {
  selectedProjectList: string[];
  onSave: () => void;
};

const MoveFolderDialog = ({
  selectedProjectList,
  onSave,
}: MoveFolderDialogProps) => {
  const { closeDialog } = useDialogStore();
  const { activeAlert } = useAlertStore();
  const { selectedFolderData, setProjectReloadTrigger } = useProjectStore();
  const groupWrapRef = useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [groupList, setGroupList] = useState<
    { label: string; value: string }[]
  >([]);

  const onSaveClick = async () => {
    if (!folderId) {
      setIsError(true);
      return;
    }

    const results = await Promise.all(
      selectedProjectList.map((project) => moveFolder(folderId, project))
    );

    if (results.every((result) => result.isSuccess)) {
      activeAlert({
        message: "프로젝트가 이동되었습니다.",
        timeout: 3000,
        type: "success",
      });
      setProjectReloadTrigger();
      onSave();
    }

    closeDialog();
  };

  const fetchFolderList = async () => {
    const result = await getFolderList();

    if (result.isSuccess) {
      if (result.isSuccess && result.resultList) {
        const targetFolderList = result.resultList.filter(
          (item: FolderItemType) =>
            item.finderId !== selectedFolderData?.finderId
        );
        setGroupList(
          targetFolderList.map((item: FolderItemType) => ({
            label: item.finderName,
            value: item.finderId,
          }))
        );
      }
    }
  };

  useEffect(() => {
    fetchFolderList();
  }, []);

  return (
    <StyledMoveFolderWrap ref={groupWrapRef}>
      <div className="folder-form-wrap-item">
        <CustomSelect
          id="folder"
          value={folderId}
          icon={<FolderIcon />}
          optionList={groupList}
          placeholder="폴더를 선택해 주세요."
          width={
            groupWrapRef.current?.clientWidth
              ? `${groupWrapRef.current?.clientWidth}px`
              : undefined
          }
          handleChange={(value) => {
            setFolderId(value);
            setIsError(false);
          }}
          error={{ isError, errorMessage: "폴더를 선택해 주세요." }}
        />
      </div>
      <div className="button-wrap">
        <CustomButton
          label="취소"
          color="primary"
          type="outlined"
          onClick={closeDialog}
          size="medium"
        />
        <CustomButton
          label="저장"
          color="primary"
          type="contained"
          onClick={onSaveClick}
          size="medium"
        />
      </div>
    </StyledMoveFolderWrap>
  );
};

export default MoveFolderDialog;
